import { isNumber } from "./baseFunction";

const inputTypePrice = (value) => {
  const valueTrim = String(value).trim();

  if (valueTrim[0] === "0" && valueTrim.length > 1) {
    return valueTrim.slice(1);
  }

  const valueSave = valueTrim.trim().replaceAll(".", "");

  if (valueSave && !isNumber(valueSave)) {
    return null;
  }

  return valueSave;
};

const inputTypeNumber = (value) => {
  if (value && !isNumber(value)) {
    return null;
  }

  return value;
};

export { inputTypePrice, inputTypeNumber };
