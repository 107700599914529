import React from "react";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";

const _nav = [
  {
    _tag: "CSidebarNavItem",
    name: "Trang chủ",
    to: "/dashboard",
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Bảng đơn hàng"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý đơn hàng",
    to: "/table/home",
    icon: "cil-list",
    badge: {
      color: "info",
      text: "NEW",
    },
    // onClick: () => console.log("Hello")
  },
  {
    _tag: "CSidebarNavItem",
    name: "Tìm kiếm đơn hàng",
    to: "/table/table-all-order",
    icon: (
      <CIcon content={freeSet.cilBasket} customClasses="c-sidebar-nav-icon" />
    ),
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Đối tác tài xế"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý tài xế",
    to: "/delivery/manager/shipper",
    icon: (
      <CIcon content={freeSet.cilBike} customClasses="c-sidebar-nav-icon" />
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý hành trình",
    to: "/delivery/map",
    icon: <CIcon content={freeSet.cilMap} customClasses="c-sidebar-nav-icon" />,
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Đối tác quán ăn"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý quán ăn",
    to: "/partner/manager",
    icon: (
      <CIcon
        content={freeSet.cilRestaurant}
        customClasses="c-sidebar-nav-icon"
      />
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý tài khoản quán ăn",
    to: "/partner/account",
    icon: (
      <CIcon content={freeSet.cilUser} customClasses="c-sidebar-nav-icon" />
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý yêu cầu",
    to: "/partner/request/all",
    icon: (
      <CIcon content={freeSet.cilShareAll} customClasses="c-sidebar-nav-icon" />
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Yêu cầu đang chờ",
    to: "/partner/request/review",
    icon: (
      <CIcon content={freeSet.cilCheck} customClasses="c-sidebar-nav-icon" />
    ),
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý chiết khấu",
    to: "/partner/collection",
    icon: (
      <CIcon content={freeSet.cilMoney} customClasses="c-sidebar-nav-icon" />
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Tích luỹ đặt hàng",
    to: "/partner/consumption",
    icon: (
      <CIcon content={freeSet.cilTablet} customClasses="c-sidebar-nav-icon" />
    ),
  },
  // {
  //   _tag: "CSidebarNavTitle",
  //   _children: ["Đối tác xe hợp đồng"],
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Quản lý xe hợp đồng",
  //   to: "/car-contract/manage",
  //   icon: (
  //     <CIcon
  //       content={freeSet.cilBusAlt}
  //       customClasses="c-sidebar-nav-icon"
  //     />
  //   ),
  // },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Đối tác massage"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý massage",
    to: "/massage/manage",
    icon: (
      <CIcon
        content={freeSet.cilHeart}
        customClasses="c-sidebar-nav-icon"
      />
    ),
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Đối tác khách sạn, nhà nghỉ"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý khách sạn, nhà nghỉ",
    to: "/hotel/manage",
    icon: (
      <CIcon
        content={freeSet.cilHouse}
        customClasses="c-sidebar-nav-icon"
      />
    ),
  },
  // {
  //   _tag: "CSidebarNavTitle",
  //   _children: ["Đối tác nhà thuốc"],
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Quản lý nhà thuốc",
  //   to: "/drugstore/manage",
  //   icon: (
  //     <CIcon
  //       content={freeSet.cilHospital}
  //       customClasses="c-sidebar-nav-icon"
  //     />
  //   ),
  // },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Khách hàng"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quản lý khách hàng",
    to: "/customer/manager",
    icon: (
      <CIcon content={freeSet.cilPeople} customClasses="c-sidebar-nav-icon" />
    ),
  },
  // {
  //   _tag: "CSidebarNavTitle",
  //   _children: ["Tiện ích"],
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Quản lý tiện ích",
  //   to: "/utilities/manager",
  //   icon: (
  //     <CIcon content={freeSet.cilFridge} customClasses="c-sidebar-nav-icon" />
  //   ),
  // },
  // {
  //   _tag: "CSidebarNavTitle",
  //   _children: ["Tin Tức"],
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Quản lý tin tức",
  //   to: "/news/manager",
  //   icon: (
  //     <CIcon
  //       content={freeSet.cilNewspaper}
  //       customClasses="c-sidebar-nav-icon"
  //     />
  //   ),
  // },

  {
    _tag: "CSidebarNavTitle",
    _children: ["Dịch vụ"],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Voucher",
    route: "/icons",
    icon: <CIcon content={freeSet.cilTag} customClasses="c-sidebar-nav-icon" />,

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Voucher ăn uống",
        to: "/voucher/normal/manage",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Voucher giao hàng",
        to: "/voucher/delivery/manage",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Voucher xe ôm",
        to: "/voucher/grab/manage",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Voucher taxi",
        to: "/voucher/taxi/manage",
      },
    ],
  },

  {
    _tag: "CSidebarNavTitle",
    _children: ["IQI SHIPPER RG"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Quảng cáo",
    to: "/mobile/customer/advertise/manage",
    icon: (
      <CIcon
        content={freeSet.cilApplications}
        customClasses="c-sidebar-nav-icon"
      />
    ),
  },
  {
    _tag: "CSidebarNavItem",
    name: "Sắp xếp quán",
    to: "/mobile/customer/manage/restaurant/sort",
    icon: (
      <CIcon
        content={freeSet.cilSortNumericUp}
        customClasses="c-sidebar-nav-icon"
      />
    ),
  },

  // {
  //   _tag: "CSidebarNavTitle",
  //   _children: ["Sự kiện"],
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Sự kiện xổ số",
  //   to: "/events/lottery",
  //   icon: (
  //     <CIcon
  //       content={freeSet.cilListNumberedRtl}
  //       customClasses="c-sidebar-nav-icon"
  //     />
  //   ),
  // },

  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Khuyến mãi",
  //   to: "/mobile/customer/manage/promo",
  //   icon: (
  //     <CIcon content={freeSet.cilMobile} customClasses="c-sidebar-nav-icon" />
  //   ),
  // },

  // {
  //   _tag: "CSidebarNavTitle",
  //   _children: ["Khác"],
  // },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Cài đặt",
  //   to: "/setting",
  //   icon: (
  //     <CIcon content={freeSet.cilSettings} customClasses="c-sidebar-nav-icon" />
  //   ),
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Theme']
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Colors',
  //   to: '/theme/colors',
  //   icon: 'cil-drop',
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Typography',
  //   to: '/theme/typography',
  //   icon: 'cil-pencil',
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Components']
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Base',
  //   route: '/base',
  //   icon: 'cil-puzzle',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Breadcrumb',
  //       to: '/base/breadcrumbs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Cards',
  //       to: '/base/cards',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Carousel',
  //       to: '/base/carousels',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Collapse',
  //       to: '/base/collapses',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Forms',
  //       to: '/base/forms',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Jumbotron',
  //       to: '/base/jumbotrons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'List group',
  //       to: '/base/list-groups',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navs',
  //       to: '/base/navs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Navbars',
  //       to: '/base/navbars',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Pagination',
  //       to: '/base/paginations',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Popovers',
  //       to: '/base/popovers',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Progress',
  //       to: '/base/progress-bar',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Switches',
  //       to: '/base/switches',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tables',
  //       to: '/base/tables',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tabs',
  //       to: '/base/tabs',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Tooltips',
  //       to: '/base/tooltips',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Buttons',
  //   route: '/buttons',
  //   icon: 'cil-cursor',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Buttons',
  //       to: '/buttons/buttons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Brand buttons',
  //       to: '/buttons/brand-buttons',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Buttons groups',
  //       to: '/buttons/button-groups',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Dropdowns',
  //       to: '/buttons/button-dropdowns',
  //     }
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Charts',
  //   to: '/charts',
  //   icon: 'cil-chart-pie'
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Icons',
  //   route: '/icons',
  //   icon: 'cil-star',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Free',
  //       to: '/icons/coreui-icons',
  //       badge: {
  //         color: 'success',
  //         text: 'NEW',
  //       },
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Flags',
  //       to: '/icons/flags',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'CoreUI Brands',
  //       to: '/icons/brands',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Notifications',
  //   route: '/notifications',
  //   icon: 'cil-bell',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Alerts',
  //       to: '/notifications/alerts',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Badges',
  //       to: '/notifications/badges',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Modal',
  //       to: '/notifications/modals',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Toaster',
  //       to: '/notifications/toaster'
  //     }
  //   ]
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Widgets',
  //   to: '/widgets',
  //   icon: 'cil-calculator',
  //   badge: {
  //     color: 'info',
  //     text: 'NEW',
  //   },
  // },
  // {
  //   _tag: 'CSidebarNavDivider'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Extras'],
  // },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Pages',
  //   route: '/pages',
  //   icon: 'cil-star',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Login',
  //       to: '/login',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Register',
  //       to: '/register',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Error 404',
  //       to: '/404',
  //     },
  //     {
  //       _tag: 'CSidebarNavItem',
  //       name: 'Error 500',
  //       to: '/500',
  //     },
  //   ],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Disabled',
  //   icon: 'cil-ban',
  //   badge: {
  //     color: 'secondary',
  //     text: 'NEW',
  //   },
  //   addLinkClass: 'c-disabled',
  //   'disabled': true
  // },
  // {
  //   _tag: 'CSidebarNavDivider',
  //   className: 'm-2'
  // },
  // {
  //   _tag: 'CSidebarNavTitle',
  //   _children: ['Labels']
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label danger',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-danger'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label info',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-info'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Label warning',
  //   to: '',
  //   icon: {
  //     name: 'cil-star',
  //     className: 'text-warning'
  //   },
  //   label: true
  // },
  // {
  //   _tag: 'CSidebarNavDivider',
  //   className: 'm-2'
  // }
];

export { _nav };
