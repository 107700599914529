const initialState = {
  play: [],
  full: [],
};

const runningDeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RUNNING_DELIVERY_PLAY": {
      return {
        ...state,
        play: action.payload,
      };
    }

    case "UPDATE_RUNNING_DELIVERY_FULL": {
      return {
        ...state,
        full: action.payload,
      };
    }

    default:
      return state;
  }
};

export default runningDeliveryReducer;
