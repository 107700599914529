const initialState = {
  visible: false,
  title: "Danh sách shipper hoạt động",
  type: "SHIPPER",
};

const modaDeliveryStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MODAL_DELIVERY_STATUS": {
      return { ...state, visible: true };
    }

    case "CLOSE_MODAL_DELIVERY_STATUS": {
      return { ...state, visible: false };
    }

    default:
      return state;
  }
};

export default modaDeliveryStatusReducer;
