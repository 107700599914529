import React, { useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

// import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
// import SearchBox from "react-google-maps/lib/components/places/SearchBox";

import { useSelector } from "react-redux";
import { apis } from "src/constants";
import { socket } from "src/socket/useWebSocket";

const MapComponent = () => {
  const trackingLocationDeliveryRedux = useSelector(
    (state) => state.trackingLocationDelivery.data
  );

  useEffect(() => {
    socket.emit("requestGetDataTrackingLocationDelivery");
  }, []);

  const options = { closeBoxURL: "", enableEventPropagation: true };

  return (
    <div>
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: 9.96047, lng: 105.117962 }}
      >
        {trackingLocationDeliveryRedux.map(
          (element) =>
            element.location && (
              <Marker
                key={element.user.id}
                icon={{
                  url:
                    apis.LOCALHOST +
                    "/assets/img/icons/customer/food_delivery.png",
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                position={{
                  lat: parseFloat(element.location.latitude),
                  lng: parseFloat(element.location.longitude),
                }}
                animation={window.google.maps.Animation.DROP}
              >
                <InfoWindow options={options}>
                  <div>
                    {element.user.code} - {element.user.name}
                  </div>
                </InfoWindow>
              </Marker>
            )
        )}
      </GoogleMap>
    </div>
  );
};

export default withScriptjs(withGoogleMap(MapComponent));
