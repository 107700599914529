const initialState = {
  data: [],
};

const toastsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_TOASTS": {
      return {
        data: [
          ...state.data,
          {
            position: "top-right",
            autohide: 3000,
            data: action.payload,
          },
        ],
      };
    }

    default:
      return state;
  }
};

export default toastsReducer;
