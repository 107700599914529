import React from "react";
import Loader from "src/views/loader/Loader";
import MapComponent from "./MapComponent";

const DeliveryMap = () => {
  const key = "AIzaSyBVaxkCUKXww35pUV9s03JzqXEzxwA00RY";

  return (
    <div className="App">
      <MapComponent
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${key}`}
        loadingElement={<Loader />}
        containerElement={
          <div
            style={{
              height: `75vh`,
              margin: `auto`,
              border: "2px solid black",
            }}
          />
        }
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
};

export default DeliveryMap;
