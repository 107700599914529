const initialState = {
  data: null,
};

const logAdminLittleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_LOG_ADMIN_LITTLE": {
      return {
        data: action.payload,
      };
    }

    case "PUSH_BEFORE_LOG_ADMIN_LITTLE": {
      return {
        data: [action.payload, ...state.data.slice(0, state.data.length - 1)],
      };
    }

    default:
      return state;
  }
};

export default logAdminLittleReducer;
