const initialState = {
  data: null,
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'UPDATE_USER': {
          return {
              data: action.payload,
          }
      }

      default:
          return state;
  }
}

export default userReducer;
