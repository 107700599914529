import React, { useEffect, useState } from "react";

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CLabel,
  CModalBody,
  CModalFooter,
  CRow,
  CSelect,
  CSpinner,
  CTextarea,
} from "@coreui/react";
import axios from "axios";
import { apis } from "src/constants";
import {
  formatPhone,
  formatPhoneForSave,
  formatPrice,
  generateString,
  maxLengthCheck,
} from "src/functions/baseFunction";
import refreshToken from "src/token/refreshToken";
import "../../../../../App.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOptionListAdmin } from "src/functions/getData";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { inputTypeNumber, inputTypePrice } from "src/functions/typeInput";
import { openImageViewer } from "src/redux/actions/imageViewer";
import {
  ID_FORM_ORDER,
  MODAL_FORM_ORDER_TYPE_CREATE,
  MODAL_FORM_ORDER_TYPE_EDIT,
} from "../RenderModalFormOrder";

const objRestaurantDefault = () => {
  return {
    id: `res-${generateString(3)}`,
    restaurant_id: null,
    restaurant_name: null,
    commission_rate: null,
  };
};

const objItemDefault = () => {
  return { id: `item-${generateString(3)}`, name: "", price: "", quantity: "" };
};

export const getOptionsPartner = (list) => {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.name,
      commission_rate: item.commission_rate,
      image_menu: item.image_menu,
    };
  });
};

export const getOptionsArea = (list) => {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.id,
    };
  });
};

export const arrayDefaultTotalOderTemps = [
  {
    key: "DEFAULT",
    title: '* Nhấn "Kiểm tra" để xem SĐT có được nhận ưu đãi',
    title_color: "#000000",
    is_show: true,
  },
  {
    key: "COLLECT_TEDDY",
    is_show: false,
  },
];

const FormOrderDefault = ({ data, handleCloseModal, type }) => {
  const dispatch = useDispatch();

  const { visible } = useSelector((state) => state.modalFormOrder);

  const userRedux = useSelector((state) => state.user.data);

  const deliveryDataSelectsRedux = useSelector(
    (state) => state.deliveryDataSelects.data
  );
  const listAdminRedux = useSelector((state) => state.listAdmin.data);
  const listRestaurantOptionRedux = useSelector(
    (state) => state.listRestaurantOption.data
  );
  const listAreaRedux = useSelector((state) => state.area.data);
  const [order, setOrder] = useState(null);
  const [validated, setValidated] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [orderRestaurant, setOrderRestaurant] = useState([]);
  const [inputPhone, setInputPhone] = useState("");
  const [totalOrderTempsAdmin, setTotalOrderTempsAdmin] = useState(
    arrayDefaultTotalOderTemps
  );
  const optionsAdmin = getOptionListAdmin(listAdminRedux);
  const optionsArea = getOptionsArea(listAreaRedux);
  const listOptionPartner = getOptionsPartner(listRestaurantOptionRedux);
  const [optionsPartner, setOptionsPartner] = useState(listOptionPartner);
  const [selectedOptionDelivery, setSelectedOptionDelivery] = useState({
    user_id: 0,
  });

  const [isLoadingTotalOrderTempsAdmin, setLoadingTotalOrderTempsAdmin] =
    useState(false);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);

  const [typeOrderIDCreate, setTypeOrderIDCreate] = useState(100);

  useEffect(() => {
    if (visible) {
      setSelectedArea(null);
      setSelectedOptionDelivery({ user_id: 0 });
      if (type === MODAL_FORM_ORDER_TYPE_EDIT) {
        document.getElementById(ID_FORM_ORDER).reset();

        if (data) {
          if (data.admin_id) {
            setSelectedAdmins(JSON.parse(data.admin_id));
          }

          if (data.bonus_delivery) {
            setSelectedOptionDelivery({ user_id: data.bonus_delivery });
          }

          if (data.area) {
            setSelectedArea({
              value: data.area,
              label: data.area,
            });
          }

          if (data.order_restaurants) {
            const matchOrderRestaurant = data.order_restaurants.map(
              (order_restaurant, index) => {
                const imageMenuThis = listRestaurantOptionRedux.find(
                  (el) => el.id === order_restaurant.restaurant.id
                )?.image_menu;

                const items = order_restaurant.order_items;

                return {
                  id: order_restaurant.id,
                  restaurant_id: order_restaurant.restaurant.id,
                  restaurant_name: order_restaurant.restaurant.name,
                  commission_rate: order_restaurant.restaurant.commission_rate,
                  image_menu: imageMenuThis,
                  items,
                  is_cancel:
                    order_restaurant.order_res_status_id === 3 ? true : false,
                };
              }
            );

            setOrderRestaurant(matchOrderRestaurant);

            const restaurantIDs = data.order_restaurants.map(
              (order_restaurant) => order_restaurant.restaurant.id
            );

            const newOptionPartner = listOptionPartner.filter(
              (el) => !restaurantIDs.includes(el.value)
            );

            setOptionsPartner(newOptionPartner);
          }

          setInputPhone(formatPhone(data.phone));

          setOrder(data);
        }
      } else if (type === MODAL_FORM_ORDER_TYPE_CREATE) {
        setSelectedAdmins([userRedux.id]);
      }
    }
  }, [visible]);

  // const fetchTotalOrderTemps = async () => {
  //   const userLocal = JSON.parse(localStorage.getItem("@loginData"));
  //   try {
  //     const response = await axios.get(
  //       apis.apiOrder +
  //         `admin/check-total-order-temps-admin/${formatPhoneForSave(
  //           inputPhone
  //         )}`,
  //       {
  //         headers: {
  //           verify: userLocal.accessToken,
  //         },
  //       }
  //     );

  //     setTotalOrderTempsAdmin(response.data);
  //   } catch (error) {
  //     if (error.response?.status === 401) {
  //       await refreshToken(userLocal);
  //       await fetchTotalOrderTemps();
  //     }
  //   }
  // };

  const handleSummitEditInfoOrder = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingSubmit(true);
      if (type === MODAL_FORM_ORDER_TYPE_CREATE) {
        await addOrderFlash(event);
      } else if (type === MODAL_FORM_ORDER_TYPE_EDIT) {
        await editOrder(event);
      }
      setLoadingSubmit(false);
    }
    setValidated(true);
  };

  const addOrderFlash = async (event) => {
    if (selectedAdmins.length === 0) {
      return alert("Vui lòng chọn nhân viên trực");
    }

    // kiểm tra chưa chọn quán hay ko
    const indexRestaurantNull =
      orderRestaurant.length > 0 &&
      orderRestaurant.findIndex((el) => !el.restaurant);

    if (indexRestaurantNull === -1) {
      return alert("Vui lòng chọn quán");
    }

    const {
      address,
      note,
      note_private,
      name_user,
      flash_detail_buy,
      delivery_charge,
      surcharge_driver
    } = event.target.elements;

    let dataSaved = {
      type_order_id: typeOrderIDCreate,
      address: address.value,
      phone: formatPhoneForSave(inputPhone),
      name_user: name_user.value ? name_user.value : "Khách hàng",
      flash_detail_buy: flash_detail_buy.value,
      note: note.value,
      note_private: note_private.value,
      area: selectedArea ? selectedArea.value : null,
      delivery_charge: delivery_charge.value
        ? `${delivery_charge.value}000`
        : 0,
      // location_customer: location_customer.value,
      // location_place: location_place.value,
      surcharge_driver: surcharge_driver.value,
      admin_id: JSON.stringify(selectedAdmins),
      bonus_delivery: selectedOptionDelivery.user_id
        ? selectedOptionDelivery.user_id
        : null,

      // total_order_temps: totalOrderTempsAdmin,
      total_order_temps: [],

      // tạo đơn quán ăn cho đơn nhanh
      orderRestaurant,
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.post(apis.apiOrder + "admin/create/traditional", dataSaved, {
        headers: {
          verify: userLocal.accessToken,
        },
      });

      document.getElementById(ID_FORM_ORDER).reset();
      setInputPhone("");
      handleCloseModal();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await addOrderFlash(event);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  const editOrder = async (event) => {
    const {
      address,
      note,
      note_private,
      name_user,
      flash_detail_buy,
      delivery_charge,
      surcharge_driver
      // location_customer,
      // location_place,
    } = event.target.elements;

    const body = {
      dataSaved: {
        address: address.value,
        area: selectedArea ? selectedArea.value : null,
        phone: formatPhoneForSave(inputPhone),
        name_user: name_user.value,
        flash_detail_buy: flash_detail_buy.value,
        note: note.value,
        note_private: note_private.value,
        delivery_charge: delivery_charge.value
          ? `${delivery_charge.value}000`
          : 0,
        // location_customer: location_customer.value,
        // location_place: location_place.value,
        surcharge_driver: surcharge_driver.value,
        admin_id: JSON.stringify(selectedAdmins),
        bonus_delivery: selectedOptionDelivery.user_id
          ? selectedOptionDelivery.user_id
          : null,

        // tạo đơn quán ăn cho đơn nhanh
        orderRestaurant,
      },
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.put(
        apis.apiOrder + `admin/update-info/traditional/${order.id}`,
        body,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      const { message, newPayable } = response.data;

      handleCloseModal();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await editOrder(event);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  const updateOptionPartners = (list) => {
    const listRestaurantID = list.map((el) => el.restaurant_id);

    const newOptionPartner = listOptionPartner.filter(
      (el) => !listRestaurantID.includes(el.value)
    );
    setOptionsPartner(newOptionPartner);
  };

  // chọn quán
  const handleChangeRestaurant = (
    restaurant_id,
    restaurant_name,
    commission_rate,
    image_menu,
    order_restaurant_id
  ) => {
    const newList = orderRestaurant.map((el) =>
      el.id === order_restaurant_id
        ? { ...el, restaurant_id, restaurant_name, commission_rate, image_menu }
        : el
    );

    setOrderRestaurant(newList);

    updateOptionPartners(newList);
  };

  // xoá quán
  const handleRemoveRestaurant = (order_restaurant_id) => {
    const newList = orderRestaurant.filter(
      (el) => el.id !== order_restaurant_id
    );

    setOrderRestaurant(newList);
    updateOptionPartners(newList);
  };
  // nhập nội dung món
  const handleChangeNameItem = (value, restaurant_id, item_id) => {
    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    const newRestaurantCurrentItems = restaurantCurrent.items.map((item) =>
      item.id === item_id ? { ...item, name: value } : item
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  // nhập nội dung món
  const handleChangePriceItem = (valueRoot, restaurant_id, item_id) => {
    const value = inputTypePrice(valueRoot);

    if (value === null) {
      return;
    }

    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    const newRestaurantCurrentItems = restaurantCurrent.items.map((item) =>
      item.id === item_id ? { ...item, price: value } : item
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  // nhập nội dung món
  const handleChangeQuantityItem = (valueRoot, restaurant_id, item_id) => {
    const value = inputTypeNumber(valueRoot);

    if (value === null) {
      return;
    }

    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    const newRestaurantCurrentItems = restaurantCurrent.items.map((item) =>
      item.id === item_id ? { ...item, quantity: value } : item
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  // xoá món
  const handleRemoveItem = (restaurant_id, item_id) => {
    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    if (restaurantCurrent.items.length === 1) {
      return alert("Không thể xoá, cần ít nhất 1 món cho mỗi quán");
    }

    const newRestaurantCurrentItems = restaurantCurrent.items.filter(
      (item) => item.id !== item_id
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  // <CCol xs="4">
  //             <CFormGroup>
  //               <CLabel
  //                 htmlFor="location_customer"
  //                 style={{ fontWeight: "bold", marginRight: 10 }}
  //               >
  //                 Toạ độ của khách
  //               </CLabel>
  //               {/* <FiInfo data-tip="" data-for="tooltip_1" size={20} />
  //                     <ReactTooltip

  //                       id="tooltip_1"
  //                       getContent={() => (
  //                         <div>
  //                           Đối với dịch vụ GIAO HÀNG thì sẽ lấy toạ độ của
  //                           người nhận hàng khi tài xế giao tới
  //                         </div>
  //                       )}
  //                     /> */}
  //               <CInput
  //                 defaultValue={
  //                   order?.latitude
  //                     ? `${order.latitude}, ${order.longitude}`
  //                     : ""
  //                 }
  //                 name="location_customer"
  //               />
  //             </CFormGroup>
  //             <CFormGroup>
  //               <CLabel
  //                 htmlFor="location_place"
  //                 style={{ fontWeight: "bold", marginRight: 10 }}
  //               >
  //                 Toạ độ mua hàng
  //               </CLabel>
  //               {/* <FiInfo data-tip="" data-for="tooltip_2" size={20} />
  //                     <ReactTooltip
  //                       id="tooltip_2"
  //                       getContent={() => (
  //                         <div>
  //                           Ăn uống: toạ độ của quán <br />
  //                           Mua theo yêu cầu: toạ độ của quán <br />
  //                           Giao hàng: toạ độ lấy hàng đem giao <br />
  //                           Xe ôm: toạ độ rước khách
  //                         </div>
  //                       )}
  //                     /> */}
  //               <CInput
  //                 defaultValue={
  //                   order?.latitude_place
  //                     ? `${order.latitude_place}, ${order.longitude_place}`
  //                     : ""
  //                 }
  //                 name="location_place"
  //               />
  //             </CFormGroup>
  //           </CCol>

  return (
    <div>
      <CModalBody>
        <CForm
          onSubmit={handleSummitEditInfoOrder}
          id={ID_FORM_ORDER}
          noValidate
          wasValidated={validated}
          autoComplete="off"
        >
          <CFormGroup row>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingInline: 15,
                ...(type === MODAL_FORM_ORDER_TYPE_CREATE
                  ? { flex: 1 }
                  : { width: "50%" }),
              }}
            >
              <Select
                isMulti
                placeholder="Chọn nhân viên trực"
                options={optionsAdmin}
                value={optionsAdmin.filter((obj) =>
                  selectedAdmins.includes(obj.value)
                )}
                onChange={(e) =>
                  setSelectedAdmins(
                    Array.isArray(e) ? e.map((x) => x.value) : []
                  )
                }
                styles={{}}
                // isDisabled={type === TYPE_EDIT ? true : false}
              />
            </div>
            {type === MODAL_FORM_ORDER_TYPE_CREATE && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingRight: 15,
                }}
              >
                <CButton
                  color={typeOrderIDCreate === 100 ? "primary" : "secondary"}
                  size="xl"
                  active
                  tabIndex={-1}
                  style={{ marginRight: 15 }}
                  disabled={typeOrderIDCreate === 100}
                  onClick={() => setTypeOrderIDCreate(100)}
                >
                  Đơn truyền thống
                </CButton>
                <CButton
                  color={typeOrderIDCreate === 20 ? "primary" : "secondary"}
                  size="xl"
                  active
                  tabIndex={-1}
                  disabled={typeOrderIDCreate === 20}
                  onClick={() => setTypeOrderIDCreate(20)}
                >
                  Đơn giao hàng cho quán
                </CButton>
              </div>
            )}
          </CFormGroup>

          <CFormGroup>
            <CLabel htmlFor="flash_detail_buy" style={{ fontWeight: "bold" }}>
              Nội dung đơn
            </CLabel>
            <CTextarea
              defaultValue={order ? order.flash_detail_buy : ""}
              name="flash_detail_buy"
              rows="5"
              required
            />
          </CFormGroup>

          {type === MODAL_FORM_ORDER_TYPE_CREATE ? (
            <CFormGroup>
              <CCard>
                <CCardHeader>
                  <CLabel
                    htmlFor="flash_detail_buy"
                    style={{ fontWeight: "bold", marginRight: 10 }}
                  >
                    Tạo đơn cho quán
                  </CLabel>
                  <CButton
                    color="primary"
                    size="sm"
                    onClick={() =>
                      setOrderRestaurant([
                        ...orderRestaurant,
                        {
                          ...objRestaurantDefault(),
                          items: [objItemDefault()],
                        },
                      ])
                    }
                  >
                    <CIcon content={freeSet.cilPlus} />
                  </CButton>
                </CCardHeader>

                {orderRestaurant.length !== 0 && (
                  <CCardBody>
                    {orderRestaurant.map((el) => (
                      <CCard
                        key={el.id}
                        style={
                          el.is_cancel
                            ? {
                                marginBottom: 30,
                                pointerEvents: "none",
                                opacity: "0.5",
                              }
                            : { marginBottom: 30 }
                        }
                      >
                        <CCardHeader>
                          <CRow
                            style={{
                              display: "flex",
                              flex: 1,
                              alignItems: "center",
                              marginTop: 10,
                              marginBottom: 10,
                              paddingRight: 3,
                            }}
                          >
                            <CCol style={{ display: "flex", flex: 1 }}>
                              <Select
                                placeholder="Chọn quán"
                                options={optionsPartner}
                                value={
                                  el.restaurant_id
                                    ? {
                                        value: el.restaurant_id,
                                        label: el.restaurant_name,
                                      }
                                    : null
                                }
                                onChange={(item) =>
                                  handleChangeRestaurant(
                                    item.value,
                                    item.label,
                                    item.commission_rate,
                                    item.image_menu,
                                    el.id
                                  )
                                }
                                styles={{
                                  container: (provided, state) => ({
                                    ...provided,
                                    display: "flex",
                                    flex: 1,
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    display: "flex",
                                    flex: 1,
                                  }),
                                }}
                              />
                            </CCol>

                            {el.restaurant_id && (
                              <CButton
                                variant="outline"
                                color={el.image_menu ? "primary" : "secondary"}
                                disabled={el.image_menu ? false : true}
                                size="sm"
                                onClick={() => {
                                  const parseImageMenus = JSON.parse(
                                    el.image_menu
                                  ).map((el) => {
                                    return apis.LOCALHOST + el;
                                  });

                                  dispatch(
                                    openImageViewer({
                                      url: parseImageMenus,
                                    })
                                  );
                                }}
                                style={{ marginRight: 10 }}
                              >
                                <CIcon content={freeSet.cilMenu} /> Menu
                              </CButton>
                            )}

                            <CButton
                              color="danger"
                              size="sm"
                              onClick={() => handleRemoveRestaurant(el.id)}
                            >
                              <CIcon content={freeSet.cilXCircle} />
                            </CButton>
                          </CRow>
                        </CCardHeader>
                        <CCardBody>
                          {el.items.map((item) => (
                            <CRow key={item.id} style={{ marginBottom: 10 }}>
                              <CCol xl="6">
                                <CInput
                                  placeholder="Tên món"
                                  value={item.name}
                                  onChange={(e) =>
                                    handleChangeNameItem(
                                      e.target.value,
                                      el.restaurant_id,
                                      item.id
                                    )
                                  }
                                  required
                                  disabled={!!item.item_id}
                                />
                              </CCol>
                              <CCol xl="3">
                                <CInput
                                  placeholder="Giá"
                                  value={formatPrice(item.price)}
                                  onChange={(e) =>
                                    handleChangePriceItem(
                                      e.target.value,
                                      el.restaurant_id,
                                      item.id
                                    )
                                  }
                                  required
                                />
                              </CCol>
                              <CCol xl="2">
                                <CInput
                                  placeholder="Số lượng"
                                  value={item.quantity}
                                  onChange={(e) =>
                                    handleChangeQuantityItem(
                                      e.target.value,
                                      el.restaurant_id,
                                      item.id
                                    )
                                  }
                                  required
                                />
                              </CCol>
                              <CCol xl="1">
                                <CButton
                                  color="warning"
                                  size="sm"
                                  onClick={() =>
                                    handleRemoveItem(el.restaurant_id, item.id)
                                  }
                                >
                                  <CIcon content={freeSet.cilMinus} />
                                </CButton>
                              </CCol>
                            </CRow>
                          ))}
                          <CButton
                            color="success"
                            size="sm"
                            onClick={() => {
                              const newData = orderRestaurant.map(
                                (restaurant) => {
                                  return restaurant.restaurant_id ===
                                    el.restaurant_id
                                    ? {
                                        ...restaurant,
                                        items: [
                                          ...restaurant.items,
                                          objItemDefault(),
                                        ],
                                      }
                                    : restaurant;
                                }
                              );
                              setOrderRestaurant(newData);
                            }}
                          >
                            + Thêm món
                          </CButton>
                        </CCardBody>
                      </CCard>
                    ))}
                  </CCardBody>
                )}
              </CCard>
            </CFormGroup>
          ) : null}

          <CRow>
            <CCol xs={"5"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Địa chỉ nơi giao/nhận hàng
                </CLabel>
                <CTextarea
                  defaultValue={order ? order.address : ""}
                  name="address"
                  rows="5"
                  required
                />
              </CFormGroup>
            </CCol>

            <CCol xs="4">
              <CFormGroup>
                <CLabel
                  htmlFor="surcharge_driver"
                  style={{ fontWeight: "bold", marginRight: 10 }}
                >
                  Chiết khấu quán
                </CLabel>
                <CInput
                  defaultValue={parseFloat(order?.surcharge_driver)}
                  name="surcharge_driver"
                  type="number"
                  onWheel={(e) => e.target.blur()}
                />
              </CFormGroup>
            </CCol>

            <CCol xs="3">
              <CFormGroup>
                <CLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                  Khu vực đơn
                </CLabel>
                <Select
                  placeholder="Chọn khu vực"
                  options={optionsArea}
                  value={selectedArea}
                  onChange={(e) => {
                    setSelectedArea(e);
                  }}
                />
              </CFormGroup>
              <CFormGroup>
                <CLabel
                  htmlFor="delivery_charge"
                  style={{ fontWeight: "bold" }}
                >
                  Phí giao hàng
                </CLabel>
                <CFormGroup row>
                  <CCol>
                    <CInputGroup>
                      <CInput
                        defaultValue={
                          order ? parseInt(order.delivery_charge) / 1000 : ""
                        }
                        name="delivery_charge"
                        type="number"
                        maxLength="4"
                        onInput={maxLengthCheck}
                        onWheel={(e) => e.target.blur()}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>.000</CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CCol>
                </CFormGroup>
              </CFormGroup>
            </CCol>
          </CRow>

          <CFormGroup row className="my-0">
            <CCol xs="5">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ flex: 1 }}>
                  <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                    SĐT nhận hàng
                  </CLabel>
                  <CInput
                    value={inputPhone}
                    name="phone"
                    type="number"
                    required
                    onChange={(e) => {
                      if (totalOrderTempsAdmin.length > 0) {
                        setTotalOrderTempsAdmin(arrayDefaultTotalOderTemps);
                      }
                      setInputPhone(e.target.value);
                    }}
                  />
                </div>
                {/* {type === MODAL_FORM_ORDER_TYPE_CREATE && (
                      <CButton
                        color="primary"
                        size="sm"
                        active
                        tabIndex={-1}
                        style={{ width: 70, marginLeft: 10 }}
                        disabled={!inputPhone || isLoadingTotalOrderTempsAdmin}
                        onClick={async () => {
                          setTotalOrderTempsAdmin(arrayDefaultTotalOderTemps);
                          setLoadingTotalOrderTempsAdmin(true);
                          setTimeout(async () => {
                            await fetchTotalOrderTemps();
                            setLoadingTotalOrderTempsAdmin(false);
                          }, 1000);
                        }}
                      >
                        {isLoadingTotalOrderTempsAdmin && (
                          <CSpinner
                            component="span"
                            size="sm"
                            aria-hidden="true"
                          />
                        )}
                        <div>Kiểm tra</div>
                      </CButton>
                    )} */}
              </div>
              {/* {totalOrderTempsAdmin.map((el) =>
                    el.is_show ? (
                      <div
                        key={el.key}
                        style={{
                          color: el.title_color ? el.title_color : COLORS.black,
                          marginTop: 5,
                          marginBottom: 10,
                        }}
                      >
                        {el.title}
                      </div>
                    ) : null
                  )} */}
            </CCol>
            <CCol xs="3">
              <CFormGroup>
                <CLabel htmlFor="name_user" style={{ fontWeight: "bold" }}>
                  Tên người nhận hàng
                </CLabel>
                <CInput
                  defaultValue={order ? order.name_user : ""}
                  name="name_user"
                  required={type === MODAL_FORM_ORDER_TYPE_EDIT ? true : false}
                />
              </CFormGroup>
            </CCol>
            <CCol xs="4">
              <CFormGroup>
                <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                  Tài xế báo về
                </CLabel>
                <CSelect
                  custom
                  name="select_delivery"
                  value={selectedOptionDelivery.user_id}
                  onChange={(event) => {
                    const userIDSelected = parseInt(event.target.value);
                    const thisDelivery = deliveryDataSelectsRedux.find(
                      (item) => item.user_id === userIDSelected
                    );
                    setSelectedOptionDelivery(thisDelivery);
                  }}
                >
                  {deliveryDataSelectsRedux.map((item, index) => (
                    <option value={item.user_id} key={index}>
                      {index !== 0
                        ? `${item.code} - ${item.user.name}`
                        : `${item.user.name}`}
                    </option>
                  ))}
                </CSelect>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6">
              <CLabel htmlFor="note" style={{ fontWeight: "bold" }}>
                Ghi chú, dặn dò cho tài xế
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note : ""}
                name="note"
                rows="2"
                placeholder={"Viết lời ghi chú, dặn dò cho tài xế (nếu có)"}
              />
            </CCol>
            <CCol xs="6">
              <CLabel htmlFor="note_private" style={{ fontWeight: "bold" }}>
                Ghi chú riêng tư
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note_private : ""}
                name="note_private"
                rows="2"
                placeholder="Ghi chú riêng tư chỉ hiển thị cho văn phòng"
              />
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={handleCloseModal}>
          Đóng
        </CButton>
        <CButton
          disabled={isLoadingSubmit}
          color={"success"}
          form={ID_FORM_ORDER}
          type={"submit"}
        >
          {isLoadingSubmit && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          {type === "create" ? "Tạo đơn" : "Cập nhật"}
        </CButton>
      </CModalFooter>
    </div>
  );
};

export default FormOrderDefault;
