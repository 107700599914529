const initialState = {
  data: [],
};

const cancelOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_CANCEL_ORDER": {
      return {
        data: action.payload,
      };
    }

    case "PUSH_BEFORE_CANCEL_ORDER": {
      const data = state.data;
      const payload = action.payload;

      const indexItem = data.findIndex((i) => i.id === payload.id);

      if (indexItem !== -1) {
        return { data };
      }

      return {
        data: [payload, ...data],
      };
    }

    default:
      return state;
  }
};

export default cancelOrderReducer;
