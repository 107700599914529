import React, { useEffect } from "react";

import {
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { useDispatch, useSelector } from "react-redux";
import { closeModalMapDelivery } from "src/redux/actions/global/modal/modalMapDelivery";
import DeliveryMap from "src/views/delivery/map";
import { socket } from "src/socket/useWebSocket";

const RenderModalMapDelivery = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector((state) => state.modalMapDelivery);

  useEffect(() => {
    if (visible) {
      socket.emit("requestGetDataTrackingLocationDelivery");
    }
  }, [visible]);

  const handleCloseModal = () => {
    dispatch(closeModalMapDelivery());
  };

  return (
    <CModal
      show={visible}
      onClose={handleCloseModal}
      color={"danger"}
      size={"xl"}
    >
      <CModalHeader closeButton>
        <CModalTitle>Bản đồ</CModalTitle>
      </CModalHeader>
      <CModalBody style={{padding: 5}}>
        <DeliveryMap />
      </CModalBody>
    </CModal>
  );
};

export default RenderModalMapDelivery;
