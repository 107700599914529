import React from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";

const RenderModal = (props) => {
  const colorButtonAccept = () => {
    if (props.disabled) {
      return "secondary";
    }

    return props.colorAccept ? props.colorAccept : "danger";
  };

  return (
    <CModal
      show={props.modalVisible}
      onClose={props.onClickCancel}
      color={props.color}
      size={props.size ? props.size : ""}
      style={{ width: props.widthModal || 500, zIndex: 100 }}
    >
      <CModalHeader closeButton>
        <CModalTitle>{props.title ? props.title : "Thông báo"}</CModalTitle>
      </CModalHeader>
      <CModalBody style={{ paddingBottom: 0 }}>{props.body}</CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={props.onClickCancel}>
          Đóng
        </CButton>
        {props.titleAccept ? (
          <CButton
            disabled={props.disabled ? true : false}
            color={colorButtonAccept()}
            onClick={props.onClickAccet}
            form={props.form ? props.form : null}
            type={props.submit ? "submit" : "button"}
          >
            {props.titleAccept}
          </CButton>
        ) : null}
      </CModalFooter>
    </CModal>
  );
};

export default RenderModal;
