import io from "socket.io-client";
import { apis } from "src/constants";

import soundNotify from "../assets/sound/notification.mp3";
import soundNotifyNewOrder from "../assets/sound/notification_up.mp3";

import { pushBeforeLogAdminLittle } from "src/redux/actions/logAdminLittle";
import { updateToasts } from "src/redux/actions/toasts";

import { pushBeforeLogDeliveryLittle } from "src/redux/actions/logDeliveryLittle";
import {
  editItemNewOrder,
  pushBeforeNewOrder,
  removeItemNewOrder,
} from "src/redux/actions/orders/newOrder";
import {
  pushBeforePointOrder,
  removeItemPointOrder,
} from "src/redux/actions/orders/pointOrder";
import {
  editItemReceivedOrder,
  pushBeforeReceivedOrder,
  removeItemReceivedOrder,
} from "src/redux/actions/orders/receivedOrder";
import { pushBeforeFinishOrder } from "src/redux/actions/orders/finishOrder";
import { pushBeforeCancelOrder } from "src/redux/actions/orders/cancelOrder";
import { updateActiveSurchargeService } from "src/redux/actions/surchargeService";
import { updateTrackingLocationDelivery } from "src/redux/actions/tracking_location";
import refreshToken from "src/token/refreshToken";
import { updateRunningDeliveryPlay } from "src/redux/actions/runningDelivery";
import axios from "axios";
import { getListRunningDeliveryPlay } from "src/containers/TheHeader";

const TIME_OUT_FETCH_FELIVERY_RUNNING = 1000;

export let socket;

export const initiateSocket = (user_id, accessToken, dispatch) => {
  if (!socket?.connected) {
    socket = io.connect(apis.LOCALHOST + "/admins", {
      reconnection: true,
      reconnectionDelay: 500,
      reconnectionAttempts: 10,
      query: { user_id, token: accessToken },
    });
  }

  socket.on("connect", () => {
    // Xử lý khi kết nối bị đứt
  });

  socket.on("disconnect", () => {
    socket.close();
    initiateSocket(user_id, accessToken, dispatch);
  });

  // Xử lý khi kết nối lại thành công
  socket.on("reconnect", () => {
    // Các hành động sau khi kết nối lại thành công
    // Ví dụ: load lại dữ liệu, gửi lại các yêu cầu chưa thành công trước đó, vv.
  });

  listenSocket(dispatch);
};

export const disconnectSocket = () => {
  if (socket) socket.disconnect();
};

const soundPlay = () => {
  new Audio(soundNotify).play();
};

const soundPlayNewOrder = () => {
  new Audio(soundNotifyNewOrder).play();
};

const listenSocket = (dispatch) => {
  socket.on("alert", (data) => {
    if (data) {
      // soundPlay();
      const alertData = `${data.user.name}\n${data.body}`;
      dispatch(updateToasts(alertData));
      dispatch(pushBeforeLogAdminLittle(data));
    }
  });

  socket.on("alertFromPartner", (message) => {
    soundPlay();
    dispatch(updateToasts(message));
  });

  socket.on("alertSupportPartner", () => {
    soundPlay();
    const alertData = "Có tin nhắn từ đối tác";
    dispatch(updateToasts(alertData));
  });

  socket.on("logDelivery", (data) => {
    if (data) {
      soundPlay();
      const alertData = `${data.delivery.user.name}(${data.delivery.code}) - ${data.body}`;
      dispatch(updateToasts(alertData));
      dispatch(pushBeforeLogDeliveryLittle(data));
    }
  });

  socket.on("newOrder", (data) => {
    if (data) {
      const { response, isNotify } = data;
      if (isNotify) {
        soundPlayNewOrder();
        dispatch(updateToasts("Có đơn hàng mới (Đơn mới)"));
      }
      dispatch(pushBeforeNewOrder(response));
    }
  });

  socket.on("pointOrder", (data) => {
    if (data) {
      const { response, isNotify } = data;
      if (isNotify) {
        soundPlayNewOrder();
        dispatch(updateToasts("Có đơn hàng mới (Đơn đang chờ)"));
      }
      setTimeout(() => {
        getListRunningDeliveryPlay(dispatch);
      }, TIME_OUT_FETCH_FELIVERY_RUNNING);

      dispatch(removeItemNewOrder(response.id));
      dispatch(pushBeforePointOrder(response));
    }
  });

  socket.on("receivedOrderFromPoint", (data) => {
    if (data) {
      dispatch(removeItemPointOrder(data.order.id));
      dispatch(pushBeforeReceivedOrder(data.order));
      if (data.isFetchDeliveryRunning) {
        setTimeout(() => {
          getListRunningDeliveryPlay(dispatch);
        }, TIME_OUT_FETCH_FELIVERY_RUNNING);
      }
      // dispatch(editItemRunningDelivery(data.delivery));
    }
  });

  socket.on("finishOrder", (data) => {
    if (data) {
      if (data.isFetchDeliveryRunning) {
        dispatch(removeItemNewOrder(data.order.id));
        dispatch(removeItemReceivedOrder(data.order.id));
        dispatch(pushBeforeFinishOrder(data.order));

        setTimeout(() => {
          getListRunningDeliveryPlay(dispatch);
        }, TIME_OUT_FETCH_FELIVERY_RUNNING);
      } else {
        dispatch(pushBeforeFinishOrder(data));
      }
    }
  });

  socket.on("cancelOrder", (data) => {
    if (data) {
      let result = data.order ? data.order : data;

      dispatch(removeItemNewOrder(result.id));
      dispatch(removeItemReceivedOrder(result.id));
      dispatch(pushBeforeCancelOrder(result));

      if (data.isFetchDeliveryRunning) {
        setTimeout(() => {
          getListRunningDeliveryPlay(dispatch);
        }, TIME_OUT_FETCH_FELIVERY_RUNNING);
      }
    }
  });

  socket.on("recallOrderFromPoint", (data) => {
    if (data) {
      dispatch(removeItemPointOrder(data.id));
      dispatch(pushBeforeNewOrder(data));
      setTimeout(() => {
        getListRunningDeliveryPlay(dispatch);
      }, TIME_OUT_FETCH_FELIVERY_RUNNING);
    }
  });

  socket.on("recallOrderFromReceive", (data) => {
    if (data) {
      dispatch(removeItemReceivedOrder(data.order.id));
      dispatch(pushBeforeNewOrder(data.order));
      if (data.isFetchDeliveryRunning) {
        setTimeout(() => {
          getListRunningDeliveryPlay(dispatch);
        }, TIME_OUT_FETCH_FELIVERY_RUNNING);
      }
    }
  });

  socket.on("updateInfoOrder", (data) => {
    if (data) {
      dispatch(editItemNewOrder(data));
      dispatch(editItemReceivedOrder(data));
    }
  });

  socket.on("reloadDeliveryRunning", (data) => {
    if (data) {
      setTimeout(() => {
        getListRunningDeliveryPlay(dispatch);
      }, TIME_OUT_FETCH_FELIVERY_RUNNING);
    }
  });

  socket.on("checkActiveSurchargeService", (data) => {
    if (data) {
      dispatch(updateActiveSurchargeService(data.is_active));
    }
  });

  socket.on("trackingLocationDelivery", (data) => {
    if (data) {
      dispatch(updateTrackingLocationDelivery(data));
    }
  });

  //   // socketRef.current.emit("sendDataClient", msg);
};
