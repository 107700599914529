const initialState = {
  data: [], // {id, name, commission_rate, image_menu}
};

const listRestaurantOptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_LIST_RESTAURANT_OPTION": {
      return {
        data: action.payload,
      };
    }

    default:
      return state;
  }
};

export default listRestaurantOptionReducer;
