const initialState = {
  data: [],
};

const restaurantsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RESTAURANTS": {
      return {
        data: action.payload,
      };
    }

    case "EDIT_RESTAURANTS": {
      const data = state.data;

      const { restaurant_id, newDataItem } = action.payload;

      const newData = data.map((item) =>
        item.id === restaurant_id ? { ...item, ...newDataItem } : item
      );

      return {
        data: newData,
      };
    }

    case "REMOVE_RESTAURANTS": {
      const data = state.data;

      const newData = data.filter((item) => item.id !== action.payload);

      return {
        data: newData,
      };
    }

    default:
      return state;
  }
};

export default restaurantsReducer;
