function formatPrice(price) {
  if (price === 0 || price === "0") {
    return "0";
  }

  if (!price) {
    return "";
  }

  let formated = "";

  const isDecimal =
    String(price).substring(price.length - 3, price.length) === ".00";

  if (isDecimal) {
    formated = String(price).substring(0, price.length - 3);
  } else {
    formated = String(price).substring(0, price.length);
  }

  formated = formated.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");

  return formated;
}

function formatPriceTypeDecimal(price) {
  if (!price) {
    return null;
  }

  let formated = String(price).substring(0, price.length - 3);
  formated = formated.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  return formated;
}

function formatPriceTypeDecimalForInput(price) {
  if (!price) {
    return null;
  }

  let formated = String(price).substring(0, price.length - 3);
  return formated;
}

const formatPhone = (phone) => {
  return phone ? phone.replace("+84", "0") : "";
};

function formatPhoneForSave(phone) {
  if (phone[0] === "0") {
    return phone.replace("0", "+84");
  } else {
    return phone;
  }
}

function checkNotEmptyObject(obj) {
  for (var key in obj) {
    if (!obj[key]) return false;
  }
  return true;
}

const checkSameData = function (obj1, obj2) {
  const obj1Length = Object.keys(obj1).length;
  const obj2Length = Object.keys(obj2).length;

  if (obj1Length === obj2Length) {
    return Object.keys(obj1).every(
      (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
    );
  }
  return false;
};

const getDifferenceBetweenTwoObject = (obj1, obj2) => {
  const listKeySave = Object.keys(obj1).filter((k) => obj1[k] !== obj2[k]);
  const obj = {};
  for (const key of listKeySave) {
    obj[key] = obj1[key];
  }
  return obj;
};

const getArrayFromOneToNumber = (number) => {
  return Array.from({ length: number }, (_, i) => i + 1);
};

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

const maxLengthCheck = (object) => {
  if (object.target.value.length > object.target.maxLength) {
    object.target.value = object.target.value.slice(0, object.target.maxLength);
  }
};

const convertDateTimeToInputTypeDateTimeLocal = (date) => {
  if (!date) {
    return null;
  }

  const dateTime = new Date(date);

  const result = new Date(
    dateTime.getTime() + new Date().getTimezoneOffset() * -60 * 1000
  )
    .toISOString()
    .slice(0, 19);
  return result;
};

function isNumber(value) {
  const myRegEx = /^([0-9]+)$/;
  const isValid = myRegEx.test(value);
  return isValid;
}

const handleChangeTypePrice = (value, setState) => {
  if (value.trim()[0] === "0") {
    return;
  }
  const valueSave = value.trim().replaceAll(".", "");
  if (valueSave && !isNumber(valueSave)) {
    return;
  }
  setState(valueSave);
};

const formatDateYYYYMMDD = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }

  return [year, month, day].join("-");
};

const generateString = (length) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
};

function formatDateDDMMYYYYHHMM(date, separatorDate) {
  const d = new Date(date);

  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  let year = d.getFullYear();
  let hour = d.getHours();
  let minute = d.getMinutes();

  if (month.length < 2) {
    month = "0" + month;
  }
  if (day.length < 2) {
    day = "0" + day;
  }
  if (String(hour).length < 2) {
    hour = "0" + hour;
  }
  if (String(minute).length < 2) {
    minute = "0" + minute;
  }

  return [day, month, year].join(separatorDate) + ` ${hour}:${minute}`;
}

function isValidHttpUrl(string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

const getValueTypePrice = (value) => {
  // Xóa các ký tự không phải là số
  const numericValue = value.replace(/[^0-9]/g, "");

  // Chuyển giá trị thành số
  const parsedValue = parseInt(numericValue, 10);

  // Kiểm tra giá trị có là NaN hay không
  if (!isNaN(parsedValue)) {
    return parsedValue;
  }

  return value;
};

const formatDistance = (distance) => {
  return Math.round((distance / 1000 + Number.EPSILON) * 10) / 10;
};

export {
  formatPrice,
  formatPriceTypeDecimal,
  formatPriceTypeDecimalForInput,
  formatPhone,
  formatPhoneForSave,
  checkNotEmptyObject,
  checkSameData,
  getDifferenceBetweenTwoObject,
  getArrayFromOneToNumber,
  range,
  maxLengthCheck,
  convertDateTimeToInputTypeDateTimeLocal,
  isNumber,
  handleChangeTypePrice,
  formatDateYYYYMMDD,
  formatDateDDMMYYYYHHMM,
  generateString,
  isValidHttpUrl,
  getValueTypePrice,
  formatDistance,
};
