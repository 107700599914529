import axios from "axios";
import React, { useEffect, useState } from "react";
import { apis } from "src/constants";
import { useLocation } from "react-router-dom";

const FaceBookStatusClearUser = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const [status, setStatus] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await fetchStatus();
    }
    fetchData();
  }, []);

  const fetchStatus = async () => {
    try {
      const response = await axios.get(
        `${apis.LOCALHOST}/facebook/status-clear-data-user/${id}`
      );

      setStatus(response.data);
    } catch (error) {}
  };

  return <h5>{status}</h5>;
};

export default FaceBookStatusClearUser;
