import React, { useEffect, useState } from "react";

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CLabel,
  CModalBody,
  CModalFooter,
  CRow,
  CSpinner,
  CTextarea,
} from "@coreui/react";
import axios from "axios";
import { COLORS, apis } from "src/constants";
import {
  formatPhone,
  formatPhoneForSave,
  formatPrice,
  generateString,
  maxLengthCheck,
} from "src/functions/baseFunction";
import refreshToken from "src/token/refreshToken";
import "../../../../../App.css";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { inputTypeNumber, inputTypePrice } from "src/functions/typeInput";
import { openImageViewer } from "src/redux/actions/imageViewer";
import { ID_FORM_ORDER } from "../RenderModalFormOrder";
import { getOptionListAdmin } from "src/functions/getData";

const objItemDefault = () => {
  return { id: `item-${generateString(3)}`, name: "", price: "", quantity: "" };
};

export const getOptionsPartner = (list) => {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.name,
      commission_rate: item.commission_rate,
      image_menu: item.image_menu,
    };
  });
};

export const getOptionsArea = (list) => {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.id,
    };
  });
};

export const arrayDefaultTotalOderTemps = [
  {
    key: "DEFAULT",
    title: '* Nhấn "Kiểm tra" để xem SĐT có được nhận ưu đãi',
    title_color: "#000000",
    is_show: true,
  },
  {
    key: "COLLECT_TEDDY",
    is_show: false,
  },
];

const FormOrderNormal = ({ data, handleCloseModal, type_order_id }) => {
  const dispatch = useDispatch();

  const listRestaurantOptionRedux = useSelector(
    (state) => state.listRestaurantOption.data
  );
  const listAdminRedux = useSelector((state) => state.listAdmin.data);

  const [order, setOrder] = useState(null);
  const [validated, setValidated] = useState(false);
  const [orderRestaurant, setOrderRestaurant] = useState([]);
  const listOptionPartner = getOptionsPartner(listRestaurantOptionRedux);
  const [optionsPartner, setOptionsPartner] = useState(listOptionPartner);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const optionsAdmin = getOptionListAdmin(listAdminRedux);

  useEffect(() => {
    if (data.order_restaurants) {
      const matchOrderRestaurant = data.order_restaurants.map(
        (order_restaurant, index) => {
          const imageMenuThis = listRestaurantOptionRedux.find(
            (el) => el.id === order_restaurant.restaurant.id
          )?.image_menu;

          const items = order_restaurant.order_items;

          return {
            id: order_restaurant.id,
            restaurant_id: order_restaurant.restaurant.id,
            restaurant_name: order_restaurant.restaurant.name,
            commission_rate: order_restaurant.restaurant.commission_rate,
            image_menu: imageMenuThis,
            items,
            is_cancel:
              order_restaurant.order_res_status_id === 3 ? true : false,
          };
        }
      );

      setOrderRestaurant(matchOrderRestaurant);

      const restaurantIDs = data.order_restaurants.map(
        (order_restaurant) => order_restaurant.restaurant.id
      );

      const newOptionPartner = listOptionPartner.filter(
        (el) => !restaurantIDs.includes(el.value)
      );

      setOptionsPartner(newOptionPartner);
    }

    if (data.admin_id) {
      setSelectedAdmins(JSON.parse(data.admin_id));
    }

    setOrder(data);
  }, []);

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingSubmit(true);
      await updateInfo(event);
      setLoadingSubmit(false);
    }
    setValidated(true);
  };

  const updateInfo = async (event) => {
    const {
      address,
      phone,
      name_user,
      delivery_charge,
      note,
      note_private,
      surcharge_driver,
    } = event.target.elements;

    const body = {
      dataSaved: {
        address: address.value,
        phone: formatPhoneForSave(phone.value),
        name_user: name_user.value,
        delivery_charge: delivery_charge.value
          ? `${delivery_charge.value}000`
          : 0,
        note: note.value,
        note_private: note_private.value,
        admin_id: JSON.stringify(selectedAdmins),
        surcharge_driver: surcharge_driver.value,
        orderRestaurant,
      },
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.put(
        apis.apiOrder + `admin/update-info/normal/${order.id}`,
        body,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      handleCloseModal();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await updateInfo(event);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  const updateOptionPartners = (list) => {
    const listRestaurantID = list.map((el) => el.restaurant_id);

    const newOptionPartner = listOptionPartner.filter(
      (el) => !listRestaurantID.includes(el.value)
    );
    setOptionsPartner(newOptionPartner);
  };

  // chọn quán
  const handleChangeRestaurant = (
    restaurant_id,
    restaurant_name,
    commission_rate,
    image_menu,
    order_restaurant_id
  ) => {
    const newList = orderRestaurant.map((el) =>
      el.id === order_restaurant_id
        ? { ...el, restaurant_id, restaurant_name, commission_rate, image_menu }
        : el
    );

    setOrderRestaurant(newList);

    updateOptionPartners(newList);
  };

  // xoá quán
  const handleRemoveRestaurant = (order_restaurant_id) => {
    const newList = orderRestaurant.filter(
      (el) => el.id !== order_restaurant_id
    );

    setOrderRestaurant(newList);
    updateOptionPartners(newList);
  };
  // nhập nội dung món
  const handleChangeNameItem = (value, restaurant_id, item_id) => {
    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    const newRestaurantCurrentItems = restaurantCurrent.items.map((item) =>
      item.id === item_id ? { ...item, name: value } : item
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  // nhập nội dung món
  const handleChangePriceItem = (valueRoot, restaurant_id, item_id) => {
    const value = inputTypePrice(valueRoot);

    if (value === null) {
      return;
    }

    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    const newRestaurantCurrentItems = restaurantCurrent.items.map((item) =>
      item.id === item_id ? { ...item, price: value } : item
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  // nhập nội dung món
  const handleChangeQuantityItem = (valueRoot, restaurant_id, item_id) => {
    const value = inputTypeNumber(valueRoot);

    if (value === null) {
      return;
    }

    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    const newRestaurantCurrentItems = restaurantCurrent.items.map((item) =>
      item.id === item_id ? { ...item, quantity: value } : item
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  // xoá món
  const handleRemoveItem = (restaurant_id, item_id) => {
    const restaurantCurrent = orderRestaurant.find(
      (el) => el.restaurant_id === restaurant_id
    );

    if (restaurantCurrent.items.length === 1) {
      return alert("Không thể xoá, cần ít nhất 1 món cho mỗi quán");
    }

    const newRestaurantCurrentItems = restaurantCurrent.items.filter(
      (item) => item.id !== item_id
    );

    const newList = orderRestaurant.map((el) =>
      el.restaurant_id === restaurant_id
        ? { ...el, items: newRestaurantCurrentItems }
        : el
    );

    setOrderRestaurant(newList);
  };

  return (
    <>
      <CModalBody>
        <CForm
          onSubmit={handleSubmitForm}
          id={ID_FORM_ORDER}
          noValidate
          wasValidated={validated}
          autoComplete="off"
        >
          <CFormGroup row>
            <CCol md="6">
              <CLabel htmlFor="delivery_charge" style={{ fontWeight: "bold" }}>
                Nhân viên trực
              </CLabel>
              <Select
                isMulti
                placeholder="Chọn nhân viên trực"
                options={optionsAdmin}
                value={optionsAdmin.filter((obj) =>
                  selectedAdmins.includes(obj.value)
                )}
                onChange={(e) =>
                  setSelectedAdmins(
                    Array.isArray(e) ? e.map((x) => x.value) : []
                  )
                }
                isDisabled={type_order_id === 1}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup>
            <CCard>
              <CCardHeader>
                <CLabel
                  htmlFor="flash_detail_buy"
                  style={{ fontWeight: "bold", marginRight: 10 }}
                >
                  Thông tin đơn hàng
                </CLabel>
                {/* <CButton
                  color="primary"
                  size="sm"
                  onClick={() =>
                    setOrderRestaurant([
                      ...orderRestaurant,
                      {
                        ...objRestaurantDefault(),
                        items: [objItemDefault()],
                      },
                    ])
                  }
                >
                  <CIcon content={freeSet.cilPlus} />
                </CButton> */}
              </CCardHeader>

              {orderRestaurant.length !== 0 && (
                <CCardBody style={{ paddingBottom: 0 }}>
                  {orderRestaurant.map((el) => (
                    <CCard
                      key={el.id}
                      style={
                        el.is_cancel
                          ? {
                              marginBottom: 30,
                              pointerEvents: "none",
                              opacity: "0.5",
                            }
                          : { marginBottom: 30 }
                      }
                    >
                      <CCardHeader>
                        <CRow
                          style={{
                            display: "flex",
                            flex: 1,
                            alignItems: "center",
                            marginTop: 10,
                            marginBottom: 10,
                            paddingRight: 3,
                          }}
                        >
                          <CCol style={{ display: "flex", flex: 1 }}>
                            <Select
                              placeholder="Chọn quán"
                              options={optionsPartner}
                              value={
                                el.restaurant_id
                                  ? {
                                      value: el.restaurant_id,
                                      label: el.restaurant_name,
                                    }
                                  : null
                              }
                              onChange={(item) =>
                                handleChangeRestaurant(
                                  item.value,
                                  item.label,
                                  item.commission_rate,
                                  item.image_menu,
                                  el.id
                                )
                              }
                              styles={{
                                container: (provided, state) => ({
                                  ...provided,
                                  display: "flex",
                                  flex: 1,
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  display: "flex",
                                  flex: 1,
                                }),
                              }}
                            />
                          </CCol>

                          {el.restaurant_id && (
                            <CButton
                              variant="outline"
                              color={el.image_menu ? "primary" : "secondary"}
                              disabled={el.image_menu ? false : true}
                              size="sm"
                              onClick={() => {
                                const parseImageMenus = JSON.parse(
                                  el.image_menu
                                ).map((el) => {
                                  return apis.LOCALHOST + el;
                                });

                                dispatch(
                                  openImageViewer({
                                    url: parseImageMenus,
                                  })
                                );
                              }}
                              style={{ marginRight: 10 }}
                            >
                              <CIcon content={freeSet.cilMenu} /> Menu
                            </CButton>
                          )}

                          <CButton
                            color="danger"
                            size="sm"
                            onClick={() => handleRemoveRestaurant(el.id)}
                          >
                            <CIcon content={freeSet.cilXCircle} />
                          </CButton>
                        </CRow>
                      </CCardHeader>
                      <CCardBody>
                        {el.items.map((item) => (
                          <CRow key={item.id} style={{ marginBottom: 10 }}>
                            <CCol xl="6">
                              <CInput
                                placeholder="Tên món"
                                value={item.name}
                                onChange={(e) =>
                                  handleChangeNameItem(
                                    e.target.value,
                                    el.restaurant_id,
                                    item.id
                                  )
                                }
                                required
                                disabled={!!item.item_id}
                              />
                            </CCol>
                            <CCol xl="3">
                              <CInput
                                placeholder="Giá"
                                value={formatPrice(item.price)}
                                onChange={(e) =>
                                  handleChangePriceItem(
                                    e.target.value,
                                    el.restaurant_id,
                                    item.id
                                  )
                                }
                                required
                              />
                            </CCol>
                            <CCol xl="2">
                              <CInput
                                placeholder="Số lượng"
                                value={item.quantity}
                                onChange={(e) =>
                                  handleChangeQuantityItem(
                                    e.target.value,
                                    el.restaurant_id,
                                    item.id
                                  )
                                }
                                required
                              />
                            </CCol>
                            <CCol xl="1">
                              <CButton
                                color="warning"
                                size="sm"
                                onClick={() =>
                                  handleRemoveItem(el.restaurant_id, item.id)
                                }
                              >
                                <CIcon content={freeSet.cilMinus} />
                              </CButton>
                            </CCol>
                          </CRow>
                        ))}
                        <CButton
                          color="success"
                          size="sm"
                          onClick={() => {
                            const newData = orderRestaurant.map(
                              (restaurant) => {
                                return restaurant.restaurant_id ===
                                  el.restaurant_id
                                  ? {
                                      ...restaurant,
                                      items: [
                                        ...restaurant.items,
                                        objItemDefault(),
                                      ],
                                    }
                                  : restaurant;
                              }
                            );
                            setOrderRestaurant(newData);
                          }}
                        >
                          + Thêm món
                        </CButton>
                      </CCardBody>
                      {el.is_cancel && (
                        <div
                          style={{
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: "bold",
                              fontSize: 20,
                              backgroundColor: "red",
                              paddingBlock: 5,
                              paddingInline: 25,
                              color: COLORS.white,
                              borderRadius: 25,
                            }}
                          >
                            Quán đã huỷ đơn
                          </div>
                        </div>
                      )}
                    </CCard>
                  ))}
                </CCardBody>
              )}
            </CCard>
          </CFormGroup>

          <CRow>
            <CCol xs={"9"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Địa chỉ nhận hàng
                </CLabel>
                <CTextarea
                  defaultValue={order ? order.address : ""}
                  name="address"
                  rows="3"
                  required
                />
              </CFormGroup>
            </CCol>
            <CCol md="3">
              <CLabel htmlFor="delivery_charge" style={{ fontWeight: "bold" }}>
                Chiết khấu quán
              </CLabel>
              <CInput
                defaultValue={order ? parseFloat(order?.surcharge_driver) : ""}
                name="surcharge_driver"
                type="number"
                onWheel={(e) => e.target.blur()}
              />
            </CCol>
          </CRow>

          <CFormGroup row className="my-0">
            <CCol xs="5">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ flex: 1 }}>
                  <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                    SĐT nhận hàng
                  </CLabel>
                  <CInput
                    defaultValue={order ? formatPhone(order.phone) : ""}
                    name="phone"
                    type="number"
                    required
                  />
                </div>
              </div>
            </CCol>
            <CCol xs="4">
              <CFormGroup>
                <CLabel htmlFor="name_user" style={{ fontWeight: "bold" }}>
                  Tên người nhận hàng
                </CLabel>
                <CInput
                  defaultValue={order ? order.name_user : ""}
                  name="name_user"
                  required
                />
              </CFormGroup>
            </CCol>
            <CCol xs="3">
              <CFormGroup>
                <CLabel
                  htmlFor="delivery_charge"
                  style={{ fontWeight: "bold" }}
                >
                  Phí giao hàng
                </CLabel>
                <CFormGroup row>
                  <CCol>
                    <CInputGroup>
                      <CInput
                        defaultValue={
                          order ? parseInt(order.delivery_charge) / 1000 : ""
                        }
                        name="delivery_charge"
                        type="number"
                        maxLength="4"
                        onInput={maxLengthCheck}
                        onWheel={(e) => e.target.blur()}
                        disabled={[9, 10].includes(type_order_id)}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>.000</CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CCol>
                </CFormGroup>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6">
              <CLabel htmlFor="note" style={{ fontWeight: "bold" }}>
                Ghi chú, dặn dò cho tài xế
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note : ""}
                name="note"
                rows="3"
                placeholder={"Viết lời ghi chú, dặn dò cho tài xế (nếu có)"}
              />
            </CCol>
            <CCol xs="6">
              <CLabel htmlFor="note_private" style={{ fontWeight: "bold" }}>
                Ghi chú riêng tư
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note_private : ""}
                name="note_private"
                rows="3"
                placeholder="Ghi chú riêng tư chỉ hiển thị cho văn phòng"
              />
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={handleCloseModal}>
          Đóng
        </CButton>
        <CButton
          disabled={isLoadingSubmit}
          color={"success"}
          form={ID_FORM_ORDER}
          type={"submit"}
        >
          {isLoadingSubmit && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Cập nhật
        </CButton>
      </CModalFooter>
    </>
  );
};

export default FormOrderNormal;
