const initialState = {
  visible: false,
  data_handle: {
    order_id: null,
  },
};

const modalFormOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MODAL_FORM_ORDER": {
      return {
        ...state,
        ...action.payload,
        visible: true,
      };
    }

    case "CLOSE_MODAL_FORM_ORDER": {
       return {
        ...state,
        visible: false,
      };
    }

    default:
      return state;
  }
};

export default modalFormOrderReducer;
