import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import axios from "axios";
import { apis } from "src/constants";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthentication } from "src/redux/actions/authentication";
import { freeSet } from "@coreui/icons";

const TheHeaderDropdown = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const userRedux = useSelector((state) => state.user.data);

  const logOut = async () => {
    try {
      await axios.put(
        apis.apiUser + `admin/logout/${userRedux.id}`,
        {},
        {
          headers: {
            verify: process.env.REACT_APP_TOKEN_LOG_OUT,
          },
        }
      );
      localStorage.removeItem("@loginData");
      dispatch(updateAuthentication(false));
      history.replace("/login");
    } catch (error) {
      alert("Lỗi");
    }
  };

  return (
    <CDropdown inNav className="c-header-nav-items mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <div className="c-avatar">
          <CImg
            src={`${apis.LOCALHOST}/assets/img/icons/avatar.png`}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-task" className="mfe-2" />
          Tasks
          <CBadge color="danger" className="mfs-auto">
            42
          </CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-comment-square" className="mfe-2" />
          Comments
          <CBadge color="warning" className="mfs-auto">
            42
          </CBadge>
        </CDropdownItem> */}
        <CDropdownItem header tag="div" color="light" className="text-center">
          <strong>Cá nhân</strong>
        </CDropdownItem>
        <CDropdownItem to={"/user/profile"}>
          <CIcon name="cil-user" className="mfe-2" />
          Thông tin tài khoản
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem to={"/user/add"}>
          <CIcon content={freeSet.cilUserPlus} className="mfe-2" />
          Tạo tài khoản mới
        </CDropdownItem>
        <CDropdownItem to={"/user/list"}>
          <CIcon content={freeSet.cilListRich} className="mfe-2" />
          Danh sách quản trị
        </CDropdownItem>
        <CDropdownItem divider />
        <CDropdownItem onClick={logOut}>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Đăng xuất
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdown;
