import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { updateUser } from "./redux/actions/user.js";
import "./scss/style.scss";

import axios from "axios";
import apis from "./constants/apis.js";
import refreshToken from "./token/refreshToken.js";
import { useSelector } from "react-redux";
import { updateAuthentication } from "./redux/actions/authentication.js";
import lazyWithRetry from "./lazyWithRetry.js";
import { disconnectSocket, initiateSocket } from "./socket/useWebSocket.js";
import FaceBookStatusClearUser from "./views/public/FaceBookStatusClearUser.js";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = lazyWithRetry(() => import("./containers/TheLayout"));

// Pages
const Login = lazyWithRetry(() => import("./views/pages/login/Login"));
// const Register = lazyWithRetry(() => import("./views/pages/register/Register"));
const Page404 = lazyWithRetry(() => import("./views/pages/page404/Page404"));
const Page500 = lazyWithRetry(() => import("./views/pages/page500/Page500"));

const NewsPublic = lazyWithRetry(() => import("./views/news/NewsPublic"));
const PostCustomer = lazyWithRetry(() =>
  import("./views/posts/customer/PostCustomer")
);

const App = () => {
  const dispatch = useDispatch();

  const authenticationRedux = useSelector((state) => state.authentication.data);

  const [isLogin, setLogin] = useState(null);

  useEffect(() => {
    async function fetchData() {
      await checkLogin();
    }
    fetchData();

    return () => {
      disconnectSocket();
    };
  }, []);

  const checkLogin = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    if (userLocal !== null) {
      try {
        const response = await axios.get(apis.apiUser + "profile", {
          headers: {
            verify: userLocal.accessToken,
            role: 1,
          },
        });

        initiateSocket(response.data.id, userLocal.accessToken, dispatch);

        dispatch(updateUser(response.data));
        dispatch(updateAuthentication(true));
        setLogin(true);
      } catch (error) {
        if (error.response?.status === 401) {
          await refreshToken(userLocal);
          await checkLogin();
        } else if (error.response?.status === 400) {
          localStorage.clear();
        }
      }
    } else {
      setLogin(false);
    }
  };

  if (isLogin === null) {
    return null;
  }
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/login"
            name="Trang Đăng Nhập"
            render={(props) =>
              !authenticationRedux ? <Login {...props} /> : <Redirect to="/" />
            }
          />
          {/* <Route
            exact
            path="/register"
            name="Trang Đăng Kí"
            render={(props) => <Register {...props} />}
          /> */}
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          <Route
            exact
            path="/news-public/:news_id"
            name="Tin Tức"
            render={(props) => <NewsPublic {...props} />}
          />
          <Route
            exact
            path="/customer-post/:id"
            name="Văn bản"
            render={(props) => <PostCustomer {...props} />}
          />
          <Route
            exact
            path="/facebook/confirm-clear"
            name="FB"
            render={(props) => <FaceBookStatusClearUser {...props} />}
          />

          <Route
            path="/"
            name="Home"
            // render={props => <TheLayout {...props}/>}
            render={(props) =>
              authenticationRedux ? (
                <TheLayout {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
