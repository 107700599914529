import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg,
} from '@coreui/react'

import CIcon from '@coreui/icons-react'

// sidebar nav config
import { updateResponsive } from 'src/redux/actions/responsive'
import { apis } from 'src/constants'
import { _nav } from './_nav'
// import { useLocation } from 'react-router-dom'


const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.responsive.sidebarShow)

  // const location = useLocation();

  return (
    <CSidebar
      show={show}
      onShowChange={(val) => dispatch(updateResponsive(val))}
    >
      <CSidebarBrand className="d-md-down-none" to="/">
        {/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        /> */}
        <CImg
            src={`${apis.LOCALHOST}/assets/img/icons/icon_company.png`}
            alt="icon_company.png"
            height={100}
          />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>

        <CCreateElement
          items={_nav}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none"/>
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
