import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CSubheader,
  CBreadcrumbRouter,
  CToaster,
  CCol,
  CToast,
  CToastHeader,
  CToastBody,
  CHeaderNavItem,
  CHeaderNavLink,
  CLabel,
  CRow,
  CCard,
  CCardHeader,
  CCardBody,
  CButton,
  CProgress,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

// routes config
import routes from "../routes";
import routes_master from "src/routes_master";

import {
  TheHeaderDropdown,
  TheHeaderDropdownLogAdmin,
  TheHeaderDropdownLogDelivery,
} from "./index";
import { updateResponsive } from "src/redux/actions/responsive";

import { apis, COLORS, SIZES, type_animation } from "src/constants";

import soundNotify from "../assets/sound/notification.mp3";

import RenderModal from "src/views/utils/RenderModal";
import axios from "axios";
import refreshToken from "src/token/refreshToken";
import { updateNewOrder } from "src/redux/actions/orders/newOrder";
import { updateReceivedOrder } from "src/redux/actions/orders/receivedOrder";
import { updateFinishOrder } from "src/redux/actions/orders/finishOrder";
import { updateCancelOrder } from "src/redux/actions/orders/cancelOrder";
import { updatelogAdminLittle } from "src/redux/actions/logAdminLittle";

import { updatePointOrder } from "src/redux/actions/orders/pointOrder";
import { updateListAdmin } from "src/redux/actions/listAdmin";
import {
  updateRunningDeliveryFull,
  updateRunningDeliveryPlay,
} from "src/redux/actions/runningDelivery";
import { Virtuoso } from "react-virtuoso";
import { updateLogDeliveryLittle } from "src/redux/actions/logDeliveryLittle";
import { initListRestaurantOption } from "src/redux/actions/listRestaurantOption";
import { freeSet } from "@coreui/icons";
import { initArea } from "src/redux/actions/area";
import { initDeliveryDataSelects } from "src/redux/actions/delivery/dataSelects";
import { updateActiveSurchargeService } from "src/redux/actions/surchargeService";
import { FiWifi } from "react-icons/fi";

import "../App.css";
import Radium, { StyleRoot } from "radium";
import {
  closeModalDeliveryStatus,
  showModalDeliveryStatus,
} from "src/redux/actions/modalDeliveryStatus";

const soundPlay = () => {
  new Audio(soundNotify).play();
};

export const getListRunningDeliveryPlay = async (dispatch) => {
  const userLocal = JSON.parse(localStorage.getItem("@loginData"));
  try {
    const response = await axios.get(
      apis.apiDeliveryRunning + `find/all/admin/play`,
      {
        headers: {
          verify: userLocal.accessToken,
        },
      }
    );

    dispatch(updateRunningDeliveryPlay(response.data));
  } catch (error) {
    if (error.response.status === 401) {
      await refreshToken(userLocal);
      await getListRunningDeliveryPlay(dispatch);
    }
  }
};

export const RenderItemDeliveyWorking = ({
  item,
  index,
  handleTopPosition,
  handleDownPosition,
  handelCancelRegister,
}) => {

  return (
    <CCol>
      <CRow
        style={{
          backgroundColor: index % 2 === 0 ? COLORS.lightGray : "white",
          padding: 7,
        }}
      >
        <FiWifi
          color={item.is_connect ? COLORS.mainComponent : COLORS.red}
          style={{ marginRight: 5, marginTop: 3 }}
        />
        <CLabel style={{ display: "flex", flex: 1 }}>{item.code}</CLabel>
        <CLabel style={{ display: "flex", flex: 4 }}>{item.name}</CLabel>
        <CLabel style={{ display: "flex", flex: 2 }}>{item.areas}</CLabel>
        <CLabel
          style={{
            display: "flex",
            flex: 2,
            justifyContent: "flex-end",
            marginRight: 10,
          }}
        >
          {item.count_order}
        </CLabel>
        <div style={{ display: "flex", flex: 2, justifyContent: "flex-end" }}>
          <CButton
            color="primary"
            size="sm"
            active
            tabIndex={-1}
            style={{ marginRight: 5 }}
            onClick={() => handleDownPosition(item.delivery_id)}
          >
            <CIcon content={freeSet.cilArrowBottom} size={"sm"} />
          </CButton>
          <CButton
            color="danger"
            size="sm"
            active
            tabIndex={-1}
            onClick={() => handelCancelRegister(item.delivery_id)}
          >
            <CIcon content={freeSet.cilAccountLogout} size={"sm"} />
          </CButton>
        </div>
      </CRow>
    </CCol>
  );
};

export const RenderItemDeliveyNotWorking = ({ item, index }) => {
  return (
    <CCol>
      <CRow
        style={{
          backgroundColor: "white",
          padding: 7,
        }}
      >
        {item.is_online && (
          <FiWifi
            color={item.is_connect ? COLORS.mainComponent : COLORS.red}
            style={{ marginRight: 5, marginTop: 3 }}
          />
        )}

        <CLabel style={{ flex: 1 }}>{item.code}</CLabel>
        <CLabel style={{ flex: 5 }}>{item.name}</CLabel>
        <CLabel style={{ flex: 2, textAlign: "end" }}>
          {item.count_order}
        </CLabel>
      </CRow>
    </CCol>
  );
};

const ModalDeliveryRunning = () => {
  const dispatch = useDispatch();
  const { visible, title } = useSelector((state) => state.modalDeliveryStatus);
  const runningDeliveryFullRedux = useSelector(
    (state) => state.runningDelivery.full
  );

  useEffect(() => {
    if (visible) {
      async function fetchData() {
        await getListRunningDeliveryFull();
      }
      fetchData();
    }
  }, [visible]);

  const getListRunningDeliveryFull = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(
        apis.apiDeliveryRunning + `admin/list-full`,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      dispatch(updateRunningDeliveryFull(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListRunningDeliveryFull();
      }
    }
  };

  const fetchTopPositionDelivery = async (delivery_id) => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.put(
        apis.apiDeliveryRunning + `top-position/${delivery_id}`,
        {},
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      alert(response.data);
      getListRunningDeliveryFull();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchTopPositionDelivery(delivery_id);
      }
    }
  };

  const fetchDownPositionDelivery = async (delivery_id) => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.put(
        apis.apiDeliveryRunning + `down-position/${delivery_id}`,
        {},
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      alert(response.data);
      getListRunningDeliveryFull();
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await fetchDownPositionDelivery(delivery_id);
      }
    }
  };

  const fetchCancelRegister = async (delivery_id) => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.put(
        apis.apiDeliveryRunning + `admin/cancel-register/${delivery_id}`,
        {},
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      // const newData = runningDeliveryFullRedux.map((el) =>
      //   el.user_id === delivery_id ? { ...el, delivery_running: null } : el
      // );

      // dispatch(updateRunningDeliveryFull(newData));

      alert(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchCancelRegister(delivery_id);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      }
    }
  };

  const isScreenMobile = window.matchMedia("(max-width: 768px)").matches;

  return (
    <RenderModal
      title={title}
      body={
        <CCol>
          <CRow>
            <CCol xl="6" style={{ padding: 0 }}>
              <CCard>
                <CCardHeader>Tài xế đang chờ đơn</CCardHeader>
                <CCardBody style={{ padding: 0 }}>
                  <Virtuoso
                    style={{ height: SIZES.height * 0.6 }}
                    data={runningDeliveryFullRedux.pending}
                    itemContent={(index, item) => (
                      <RenderItemDeliveyWorking
                        item={item}
                        index={index}
                        handleTopPosition={fetchTopPositionDelivery}
                        handleDownPosition={fetchDownPositionDelivery}
                        handelCancelRegister={fetchCancelRegister}
                      />
                    )}
                  />
                </CCardBody>
              </CCard>
            </CCol>

            <CCol xl="3" style={{ padding: 0 }}>
              <CCard>
                <CCardHeader>Tài xế đang chạy</CCardHeader>
                <CCardBody style={{ padding: 0 }}>
                  <Virtuoso
                    style={{ height: SIZES.height * 0.6 }}
                    data={runningDeliveryFullRedux.running}
                    itemContent={(index, item) => (
                      <RenderItemDeliveyNotWorking item={item} index={index} />
                    )}
                  />
                </CCardBody>
              </CCard>
            </CCol>

            <CCol xl="3" style={{ padding: 0 }}>
              <CCard>
                <CCardHeader>Tài xế xuống tài</CCardHeader>
                <CCardBody style={{ padding: 0 }}>
                  <Virtuoso
                    style={{ height: SIZES.height * 0.6 }}
                    data={runningDeliveryFullRedux.offline}
                    itemContent={(index, item) => (
                      <RenderItemDeliveyNotWorking item={item} index={index} />
                    )}
                  />
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCol>
      }
      color="info"
      widthModal={!isScreenMobile && SIZES.width * 0.7}
      size="lg"
      modalVisible={visible}
      onClickCancel={() => dispatch(closeModalDeliveryStatus())}
    />
  );
};

const ComponentSurchargeService = () => {
  const { is_active } = useSelector((state) => state.surchargeService);

  const [isSurchargeServiceActive, setSurchargeServiceActive] =
    useState(is_active);

  const [typeAnimation, setTypeAnimation] = useState(null);

  const secondAnimation = 1.5;

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    let duration = 0;

    if (!is_active) {
      duration = secondAnimation * 1000;
      setTypeAnimation(type_animation.typeBounceOutRight);
    } else {
      setTypeAnimation(type_animation.typeBounceInRight);
    }

    setTimeout(() => {
      setSurchargeServiceActive(is_active);
    }, duration);
  }, [is_active]);

  useEffect(() => {
    if (is_active) {
      let objType = null;

      if (typeAnimation?.key === "bounceInRight") {
        objType = type_animation.typeTada;
      }

      if (typeAnimation?.key === "tada") {
        objType = type_animation.typeRubberBand;
      }

      if (typeAnimation?.key === "rubberBand") {
        objType = type_animation.typeTada;
      }

      if (objType) {
        setTimeout(() => {
          if (is_active) {
            setTypeAnimation(objType);
          }
        }, 2000);
      }
    }
  }, [typeAnimation]);

  return (
    <div
      style={
        {
          // position: "fixed",
          // right: 100,
          // bottom: 50,
        }
      }
    >
      {typeAnimation && isSurchargeServiceActive && (
        <StyleRoot>
          <div
            style={{
              width: 150,
              display: "flex",
              flexDirection: "column",
              paddingRight: 15,
              paddingLeft: 15,

              animation: `x ${secondAnimation}s`,
              animationName: Radium.keyframes(
                typeAnimation.val,
                typeAnimation.key
              ),
            }}
          >
            <CProgress animated value={150} color={"warning"} />
            <div
              style={{
                fontWeight: "bold",
                fontSize: 10,
                textAlign: "center",
                color: COLORS.black,
              }}
            >
              Đang chạy phụ thu
            </div>
          </div>
        </StyleRoot>
      )}
    </div>
  );
};

const TheHeader = () => {
  const dispatch = useDispatch();
  const userRedux = useSelector((state) => state.user.data);
  const sidebarShow = useSelector((state) => state.responsive.sidebarShow);

  const toastsRedux = useSelector((state) => state.toasts.data);

  const routesAuth = userRedux.master ? routes_master : routes;

  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(sidebarShow)
      ? false
      : "responsive";
    dispatch(updateResponsive(val));
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(sidebarShow)
      ? true
      : "responsive";
    dispatch(updateResponsive(val));
  };

  const [modalVisibleWelcome, setModalVisibleWelcome] = useState(true);

  const toasters = (() => {
    return toastsRedux.reduce((toasters, toast) => {
      toasters[toast.position] = toasters[toast.position] || [];
      toasters[toast.position].push(toast);
      return toasters;
    }, {});
  })();

  useEffect(() => {
    async function fetchData() {
      await initDataFirst();
    }
    fetchData();
  }, []);

  const initDataFirst = async () => {
    await getListAdmin();
    await getListNewOrder();
    await getListReceivedOrder();
    await getListFinishOrder();
    await getListCancelOrder();
    await getListPointOrder();
    await getListLogAdminLittle();
    await getListLogDeliveryLittle();
    await getListRunningDeliveryPlay(dispatch);
    await fetchListRestaurantOption();
    await fetchListArea();
    await getDeliveryDataSelect();
    await fetchCheckActiveSurchargeService();
  };

  const getListAdmin = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiUser + "admin/list-admin", {
        headers: {
          verify: userLocal.accessToken,
        },
      });
      dispatch(updateListAdmin(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListAdmin();
      }
    }
  };

  const getListNewOrder = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiOrder + "admin-new-orders", {
        headers: {
          verify: userLocal.accessToken,
        },
      });
      dispatch(updateNewOrder(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListNewOrder();
      }
    }
  };

  const getListReceivedOrder = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiOrder + "received-orders", {
        headers: {
          verify: userLocal.accessToken,
        },
      });
      dispatch(updateReceivedOrder(response.data));
    } catch (error) {
      if (error.response.status && error.response.status === 401) {
        await refreshToken(userLocal);
        await getListReceivedOrder();
      }
    }
  };

  const getListFinishOrder = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiOrder + "finish-orders", {
        headers: {
          verify: userLocal.accessToken,
        },
      });
      dispatch(updateFinishOrder(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListFinishOrder();
      }
    }
  };

  const getListCancelOrder = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiOrder + "cancel-orders", {
        headers: {
          verify: userLocal.accessToken,
        },
      });
      dispatch(updateCancelOrder(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListCancelOrder();
      }
    }
  };

  const getListPointOrder = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(
        apis.apiOrder + "point-delivery-orders",
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );
      dispatch(updatePointOrder(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListPointOrder();
      }
    }
  };

  const getListLogAdminLittle = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiLogAdmin + "find-limit-5", {
        headers: {
          verify: userLocal.accessToken,
        },
      });
      dispatch(updatelogAdminLittle(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListLogAdminLittle();
      }
    }
  };

  const getListLogDeliveryLittle = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiLogDelivery + "find-limit-5", {
        headers: {
          verify: userLocal.accessToken,
        },
      });
      dispatch(updateLogDeliveryLittle(response.data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getListLogDeliveryLittle();
      }
    }
  };

  const fetchListRestaurantOption = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(
        apis.apiRestaurant + `admin/all/attributes/2`,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      const data = response.data;

      dispatch(initListRestaurantOption(data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken(userLocal);
        await fetchListRestaurantOption();
      }
    }
  };

  const fetchListArea = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiArea + `admin/all`, {
        headers: {
          verify: userLocal.accessToken,
        },
      });

      const data = response.data;

      dispatch(initArea(data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken(userLocal);
        await fetchListArea();
      }
    }
  };

  const getDeliveryDataSelect = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(
        apis.apiDelivery + `find-all-userid-code-name`,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );
      const data = [
        { user_id: 0, user: { name: "-- Chọn tài xế --" } },
        ...response.data,
      ];

      dispatch(initDeliveryDataSelects(data));
    } catch (error) {
      if (error.response.status === 401) {
        await refreshToken(userLocal);
        await getDeliveryDataSelect();
      }
    }
  };

  const fetchCheckActiveSurchargeService = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(
        apis.apiSurchargeService + "admin/check-active",
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );
      const { is_active } = response.data;

      dispatch(updateActiveSurchargeService(is_active));
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchCheckActiveSurchargeService();
      }
    }
  };

  function renderToaster() {
    return (
      <CCol sm="12" lg="6">
        {Object.keys(toasters).map((toasterKey) => (
          <CToaster position={toasterKey} key={"toaster" + toasterKey}>
            {toasters[toasterKey].map((toast, key) => {
              return (
                <CToast
                  key={"toast" + key}
                  show={true}
                  autohide={toast.autohide}
                  fade={toast.fade}
                >
                  <CToastHeader closeButton={toast.closeButton}>
                    <svg
                      className="rounded me-2"
                      width="20"
                      height="20"
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="xMidYMid slice"
                      focusable="false"
                      role="img"
                    >
                      <rect width="100%" height="100%" fill="#007aff"></rect>
                    </svg>
                    <strong className="me-auto" style={{ marginLeft: 10 }}>
                      Thông báo
                    </strong>
                  </CToastHeader>
                  <CToastBody>
                    <div
                      className="text-line-limit-detail"
                      dangerouslySetInnerHTML={{ __html: toast.data }}
                    />
                  </CToastBody>
                </CToast>
              );
            })}
          </CToaster>
        ))}
      </CCol>
    );
  }

  function renderModalWelcome() {
    return (
      <RenderModal
        body="Chúc bạn có một ngày làm việc vui vẻ !!"
        color="info"
        modalVisible={modalVisibleWelcome}
        onClickCancel={() => {
          setModalVisibleWelcome(false);
          soundPlay();
        }}
      />
    );
  }

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink onClick={() => dispatch(showModalDeliveryStatus())}>
            Danh sách shipper hoạt động
          </CHeaderNavLink>
        </CHeaderNavItem>
        {/* <CHeaderNavItem className="px-3">
          <CHeaderNavLink
            onClick={() =>
              setModalVisibleDeliveryRunningFull({
                visible: true,
                title: "Danh sách taxi hoạt động",
                type: "TAXI",
              })
            }
          >
            Danh sách taxi hoạt động
          </CHeaderNavLink>
        </CHeaderNavItem> */}

        {/* <CHeaderNavItem  className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink>Settings</CHeaderNavLink>
        </CHeaderNavItem> */}
      </CHeaderNav>
      <CHeaderNav className="px-3">
        <ComponentSurchargeService />
        {/* <TheHeaderDropdownNotif/>
        <TheHeaderDropdownTasks/> */}
        <TheHeaderDropdownLogDelivery />
        <TheHeaderDropdownLogAdmin />
        <TheHeaderDropdown />
      </CHeaderNav>
      <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter
          className="border-0 c-subheader-nav m-0 px-0 px-md-3"
          routes={routesAuth}
        />
        {/* <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink className="c-subheader-nav-link"href="#">
              <CIcon name="cil-speech" alt="Settings" />
            </CLink>
            <CLink
              className="c-subheader-nav-link"
              aria-current="page"
              to="/dashboard"
            >
              <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
            <CLink className="c-subheader-nav-link" href="#">
              <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
            </CLink>
          </div> */}
      </CSubheader>
      {renderModalWelcome()}
      {renderToaster()}
      <ModalDeliveryRunning />
    </CHeader>
  );
};

export default TheHeader;
