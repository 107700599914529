import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CLabel,
  CModalBody,
  CModalFooter,
  CRow,
  CSpinner,
  CTextarea,
} from "@coreui/react";
import { ID_FORM_ORDER } from "../RenderModalFormOrder";
import axios from "axios";
import { apis } from "src/constants";
import refreshToken from "src/token/refreshToken";
import {
  formatPhone,
  formatPhoneForSave,
  maxLengthCheck,
} from "src/functions/baseFunction";
import Select from "react-select";
import { getOptionListAdmin } from "src/functions/getData";
import { useSelector } from "react-redux";

const FormOrderGrab = ({ data, handleCloseModal }) => {
  const listAdminRedux = useSelector((state) => state.listAdmin.data);

  const [validated, setValidated] = useState(false);
  const [order, setOrder] = useState(null);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const optionsAdmin = getOptionListAdmin(listAdminRedux);

  useEffect(() => {
    if (data.admin_id) {
      setSelectedAdmins(JSON.parse(data.admin_id));
    }

    setOrder(data);
  }, []);

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingSubmit(true);
      await updateInfo(event);
      setLoadingSubmit(false);
    }
    setValidated(true);
  };

  const updateInfo = async (event) => {
    const {
      phone,
      name_user,
      address_start,
      coordinates_start,

      address_end,
      coordinates_end,

      delivery_charge,
      note,
      note_private,
    } = event.target.elements;

    const body = {
      name_user: name_user.value,
      phone: formatPhoneForSave(phone.value),
      address_start: address_start.value,
      coordinates_start: coordinates_start.value,
      address_end: address_end.value,
      coordinates_end: coordinates_end.value,
      delivery_charge: delivery_charge.value
        ? parseFloat(delivery_charge.value) * 1000
        : 0,
      note: note.value,
      note_private: note_private.value,
      admin_id: JSON.stringify(selectedAdmins),
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.put(
        apis.apiOrder + `admin/update-info/grab/${order.id}`,
        body,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      handleCloseModal();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await updateInfo(event);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  return (
    <div>
      <CModalBody>
        <CForm
          onSubmit={handleSubmitForm}
          id={ID_FORM_ORDER}
          noValidate
          wasValidated={validated}
          autoComplete="off"
        >
          <CFormGroup row>
            <CCol md="9">
              <CLabel htmlFor="delivery_charge" style={{ fontWeight: "bold" }}>
                Nhân viên trực
              </CLabel>
              <Select
                isMulti
                placeholder="Chọn nhân viên trực"
                options={optionsAdmin}
                value={optionsAdmin.filter((obj) =>
                  selectedAdmins.includes(obj.value)
                )}
                onChange={(e) =>
                  setSelectedAdmins(
                    Array.isArray(e) ? e.map((x) => x.value) : []
                  )
                }
                // isDisabled={type === TYPE_EDIT ? true : false}
              />
            </CCol>
            <CCol xs="3">
              <CFormGroup>
                <CLabel
                  htmlFor="delivery_charge"
                  style={{ fontWeight: "bold" }}
                >
                  Tiền xe
                </CLabel>
                <CFormGroup row>
                  <CCol>
                    <CInputGroup>
                      <CInput
                        defaultValue={
                          order ? parseInt(order.delivery_charge) / 1000 : ""
                        }
                        name="delivery_charge"
                        type="number"
                        maxLength="4"
                        onInput={maxLengthCheck}
                        onWheel={(e) => e.target.blur()}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>.000</CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CCol>
                </CFormGroup>
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CCard>
            <CCardHeader>Thông tin liên hệ khách</CCardHeader>
            <CCardBody style={{ paddingBottom: 0 }}>
              <CFormGroup row>
                <CCol xs="8">
                  <CFormGroup>
                    <CLabel htmlFor="name_user" style={{ fontWeight: "bold" }}>
                      Tên liên hệ
                    </CLabel>
                    <CInput
                      defaultValue={order ? order.name_user : ""}
                      name="name_user"
                      required
                    />
                  </CFormGroup>
                </CCol>
                <CCol xs="4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                        SĐT liên hệ
                      </CLabel>
                      <CInput
                        defaultValue={order ? formatPhone(order.phone) : ""}
                        name="phone"
                        type="number"
                        required
                      />
                    </div>
                  </div>
                </CCol>
              </CFormGroup>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader>Thông tin điểm đón khách</CCardHeader>
            <CCardBody style={{ paddingBottom: 0 }}>
              <CRow>
                <CCol xs={"8"}>
                  <CFormGroup>
                    <CLabel
                      htmlFor="address_start"
                      style={{ fontWeight: "bold" }}
                    >
                      Địa chỉ
                    </CLabel>
                    <CTextarea
                      defaultValue={order ? order.address : ""}
                      name="address_start"
                      rows="3"
                      required
                    />
                  </CFormGroup>
                </CCol>
                <CCol xs={"4"}>
                  <CFormGroup>
                    <CLabel
                      htmlFor="coordinates_start"
                      style={{ fontWeight: "bold" }}
                    >
                      Toạ độ
                    </CLabel>
                    <CInput
                      defaultValue={
                        order ? `${order.latitude}, ${order.longitude}` : ""
                      }
                      name="coordinates_start"
                      required
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader>Thông tin điểm chở tới</CCardHeader>
            <CCardBody style={{ paddingBottom: 0 }}>
              <CRow>
                <CCol xs={"8"}>
                  <CFormGroup>
                    <CLabel
                      htmlFor="address_end"
                      style={{ fontWeight: "bold" }}
                    >
                      Địa chỉ
                    </CLabel>
                    <CTextarea
                      defaultValue={order ? order.service_grab.address : ""}
                      name="address_end"
                      rows="3"
                      required
                    />
                  </CFormGroup>
                </CCol>
                <CCol xs={"4"}>
                  <CFormGroup>
                    <CLabel
                      htmlFor="coordinates_end"
                      style={{ fontWeight: "bold" }}
                    >
                      Toạ độ
                    </CLabel>
                    <CInput
                      defaultValue={
                        order
                          ? `${order.service_grab.latitude}, ${order.service_grab.longitude}`
                          : ""
                      }
                      name="coordinates_end"
                      required
                    />
                  </CFormGroup>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>

          <CFormGroup row>
            <CCol xs="6">
              <CLabel htmlFor="note" style={{ fontWeight: "bold" }}>
                Ghi chú, dặn dò cho tài xế
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note : ""}
                name="note"
                rows="2"
                placeholder={"Viết lời ghi chú, dặn dò cho tài xế (nếu có)"}
              />
            </CCol>
            <CCol xs="6">
              <CLabel htmlFor="note_private" style={{ fontWeight: "bold" }}>
                Ghi chú riêng tư
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note_private : ""}
                name="note_private"
                rows="2"
                placeholder="Ghi chú riêng tư chỉ hiển thị cho văn phòng"
              />
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={handleCloseModal}>
          Đóng
        </CButton>
        <CButton
          disabled={isLoadingSubmit}
          color={"success"}
          form={ID_FORM_ORDER}
          type={"submit"}
        >
          {isLoadingSubmit && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Cập nhật
        </CButton>
      </CModalFooter>
    </div>
  );
};

export default FormOrderGrab;
