import React, { useEffect, useState } from "react";

import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CInvalidFeedback,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
  CSelect,
  CSpinner,
  CTextarea,
} from "@coreui/react";
import axios from "axios";
import Select from "react-select";
import { apis } from "src/constants";
import {
  formatPhone,
  formatPhoneForSave,
  maxLengthCheck,
} from "src/functions/baseFunction";
import { getOptionListAdmin, getUniqueOrderId } from "src/functions/getData";
import refreshToken from "src/token/refreshToken";
import CIcon from "@coreui/icons-react";
import { freeSet } from "@coreui/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateIsPartner } from "src/redux/actions/isPartners";
import { closeModalInputOrderFinish } from "src/redux/actions/modalInputOrderFinish";
import { getOptionsPartner } from "src/views/global/modal/update_order/RenderModalFormOrder";

const getOptionsArea = (list) => {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.id,
    };
  });
};

const RenderModalInputOrderFinish = () => {
  const dispatch = useDispatch();

  const { visible } = useSelector((state) => state.modalInputOrderFinish);

  const listAdminRedux = useSelector((state) => state.listAdmin.data);
  const deliveryDataSelectsRedux = useSelector(
    (state) => state.deliveryDataSelects.data
  );

  const isPartnersRedux = useSelector((state) => state.isPartners.data);

  const listAreaRedux = useSelector((state) => state.area.data);

  const [uniqueOrder, setUniqueOrder] = useState("");

  const [validated, setValidated] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);

  const [selectedArea, setSelectedArea] = useState(null);

  const [selectedOptionDelivery, setSelectedOptionDelivery] = useState({
    user_id: 0,
  });

  const [order, setOrder] = useState(null);

  const [orderRestaurant, setOrderRestaurant] = useState([]);

  const [optionsPartner, setOptionsPartner] = useState([]);

  const [isLoadingSubmit, setLoadingSubmit] = useState(false);

  const optionsAdmin = getOptionListAdmin(listAdminRedux);

  const optionsArea = getOptionsArea(listAreaRedux);

  const ID_FORM = "MODAL_INPUT_ORDER_FINISH";

  const textPlaceHolder = "Vui lòng điền đầy đủ thông tin yêu cầu cụ thể";

  const handleCloseModal = () => {
    dispatch(closeModalInputOrderFinish());
  };

  useEffect(() => {
    if (visible) {
      if (isPartnersRedux.length === 0) {
        fetchIsPartners();
      } else {
        if (optionsPartner.length === 0) {
          const options = getOptionsPartner(isPartnersRedux);
          setOptionsPartner(options);
        }
      }
    }
  }, [visible]);

  const fetchIsPartners = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(
        apis.apiRestaurant + `find/all/is-partner`,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      const data = response.data;

      const options = getOptionsPartner(data);
      setOptionsPartner(options);

      dispatch(updateIsPartner(data));
    } catch (error) {
      if (error.response && error.response.status === 401) {
        await refreshToken(userLocal);
        await fetchIsPartners();
      }
    }
  };

  const handleSummitEditInfoOrder = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      if (selectedAdmins.length === 0) {
        alert("Vui lòng chọn nhân viên trực");
      } else if (selectedOptionDelivery.user_id === 0) {
        return alert("Bạn chưa chọn tài xế giao");
      } else {
        setLoadingSubmit(true);
        await createFinishOrder(event);
        setLoadingSubmit(false);
      }
    }
    setValidated(true);
  };

  const createFinishOrder = async (event) => {
    const {
      address,
      phone,
      note,
      name_user,
      flash_detail_buy,
      delivery_charge,
    } = event.target.elements;

    const unique_order_id = getUniqueOrderId();

    let dataSaved = {
      order: {
        unique_order_id: unique_order_id,
        address: address.value,
        phone: formatPhoneForSave(phone.value),
        name_user: name_user.value ? name_user.value : "Khách hàng",
        flash_detail_buy: flash_detail_buy.value,
        note: note.value,
        area: selectedArea ? selectedArea.value : null,
        delivery_charge: `${delivery_charge.value}000`,
        admin_id: JSON.stringify(selectedAdmins),

        // tạo đơn quán ăn cho đơn nhanh
        orderRestaurant,
      },
      orderDelivery: { delivery_id: selectedOptionDelivery.user_id },
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.post(
        apis.apiOrderDelivery + "create-finish-order",
        dataSaved,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      document.getElementById(ID_FORM).reset();
      setSelectedArea(null);
      handleCloseModal();

      alert(response.data);
    } catch (error) {
      if (error.response.status && error.response.status === 401) {
        await refreshToken(userLocal);
        await createFinishOrder(event);
      } else if (error.response.status && error.response.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  const inputOrderWithUnique = async () => {
    const unique = uniqueOrder.trim();
    if (unique) {
      const userLocal = JSON.parse(localStorage.getItem("@loginData"));
      try {
        const response = await axios.get(
          apis.apiOrder + `input-order/${unique}`,
          {
            headers: {
              verify: userLocal.accessToken,
            },
          }
        );

        const data = response.data;

        if (!data) {
          alert("Mã đơn không tồn tại. Vui lòng kiểm tra lại");
        } else {
          document.getElementById(ID_FORM).reset();
          setOrder(data);
        }
      } catch (error) {
        if (error.response.status && error.response.status === 401) {
          await refreshToken(userLocal);
          await inputOrderWithUnique();
        } else if (error.response.status && error.response.status === 400) {
          alert(error.response.data);
        } else {
          alert(error.response.data);
        }
      }
    }
  };

  // chọn quán
  const handleChangeRestaurant = (value, index) => {
    const newList = orderRestaurant.map((el, index_map) =>
      index_map === index ? { ...el, restaurant_id: value } : el
    );
    setOrderRestaurant(newList);
  };

  // nhập chi tiết
  const handleChangeDetail = (value, index) => {
    const newList = orderRestaurant.map((el, index_map) =>
      index_map === index ? { ...el, detail: value } : el
    );
    setOrderRestaurant(newList);
  };

  return (
    <CModal
      show={visible}
      onClose={handleCloseModal}
      color={"success"}
      style={{ width: 800, zIndex: 100 }}
    >
      <CModalHeader closeButton>
        <CModalTitle>Nhập đơn</CModalTitle>
      </CModalHeader>
      <CModalBody style={{ paddingBottom: 0 }}>
        <div>
          <CForm
            onSubmit={handleSummitEditInfoOrder}
            id={ID_FORM}
            noValidate
            wasValidated={validated}
            autoComplete="off"
          >
            {/* <CCard> */}
            <CCardBody style={{ paddingBottom: 0 }}>
              <CFormGroup row>
                <CCol md="6">
                  <CInputGroup>
                    <CInput
                      name="input-unique"
                      placeholder="Nhập mã đơn"
                      value={uniqueOrder}
                      onChange={(e) => setUniqueOrder(e.target.value)}
                      type="number"
                    />
                    <CInputGroupAppend>
                      <CButton
                        type="button"
                        color="primary"
                        onClick={inputOrderWithUnique}
                      >
                        Nhập vào
                      </CButton>
                      <CButton
                        type="button"
                        color="danger"
                        onClick={() => {
                          setUniqueOrder("");
                          setSelectedAdmins([]);
                          document.getElementById(ID_FORM).reset();
                        }}
                      >
                        Xoá sạch
                      </CButton>
                    </CInputGroupAppend>
                  </CInputGroup>
                </CCol>

                <CCol md="6">
                  <Select
                    isMulti
                    placeholder="Chọn nhân viên trực"
                    options={optionsAdmin}
                    value={optionsAdmin.filter((obj) =>
                      selectedAdmins.includes(obj.value)
                    )}
                    onChange={(e) =>
                      setSelectedAdmins(
                        Array.isArray(e) ? e.map((x) => x.value) : []
                      )
                    }
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup>
                <CLabel
                  htmlFor="flash_detail_buy"
                  style={{ fontWeight: "bold" }}
                >
                  (*) Nội dung đơn
                </CLabel>
                <CTextarea
                  defaultValue={order ? order.flash_detail_buy : ""}
                  name="flash_detail_buy"
                  rows="5"
                  placeholder={textPlaceHolder}
                  required
                />
                <CInvalidFeedback>{textPlaceHolder}</CInvalidFeedback>
              </CFormGroup>

              <CFormGroup>
                <CCard>
                  <CCardHeader>
                    <CLabel
                      htmlFor="flash_detail_buy"
                      style={{ fontWeight: "bold", marginRight: 10 }}
                    >
                      Tạo đơn cho quán
                    </CLabel>
                    <CButton
                      color="primary"
                      size="sm"
                      onClick={() =>
                        setOrderRestaurant([
                          ...orderRestaurant,
                          { restaurant_id: null, detail: "" },
                        ])
                      }
                    >
                      <CIcon content={freeSet.cilPlus} />
                    </CButton>
                  </CCardHeader>

                  {orderRestaurant.length !== 0 && (
                    <CCardBody>
                      {orderRestaurant.map((el, index) => (
                        <CRow key={index} style={{ marginTop: 10 }}>
                          <CCol xl="4">
                            <Select
                              name="color"
                              placeholder="Chọn quán"
                              isLoading={
                                optionsPartner.length === 0 ? true : false
                              }
                              options={optionsPartner}
                              value={optionsPartner.find(
                                (el_find) => el_find.value === el.restaurant_id
                              )}
                              onChange={(item) =>
                                handleChangeRestaurant(item.value, index)
                              }
                            />
                          </CCol>
                          <CCol xl="7">
                            <CTextarea
                              rows="3"
                              value={el.detail}
                              onChange={(e) =>
                                handleChangeDetail(e.target.value, index)
                              }
                            />
                          </CCol>

                          <CCol xl="1">
                            <CButton
                              color="danger"
                              size="sm"
                              onClick={() => {
                                const listCurrent = orderRestaurant;
                                listCurrent.splice(index, 1);

                                const newList = listCurrent.map(
                                  (el_map) => el_map
                                );

                                setOrderRestaurant(newList);
                              }}
                            >
                              <CIcon content={freeSet.cilXCircle} />
                            </CButton>
                          </CCol>
                        </CRow>
                      ))}
                    </CCardBody>
                  )}
                </CCard>
              </CFormGroup>

              <CRow>
                <CCol xs="8">
                  <CFormGroup>
                    <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                      (*) Địa chỉ nơi giao/nhận hàng
                    </CLabel>
                    <CTextarea
                      defaultValue={order ? order.address : ""}
                      name="address"
                      rows="5"
                      placeholder={textPlaceHolder}
                      required
                    />
                    <CInvalidFeedback>{textPlaceHolder}</CInvalidFeedback>
                  </CFormGroup>
                </CCol>
                <CCol xs="4">
                  <CFormGroup>
                    <CLabel htmlFor="area" style={{ fontWeight: "bold" }}>
                      Khu vực đơn
                    </CLabel>
                    <Select
                      placeholder="Chọn khu vực"
                      options={optionsArea}
                      value={selectedArea}
                      onChange={(e) => {
                        setSelectedArea(e);
                      }}
                    />
                  </CFormGroup>
                  <CFormGroup>
                    <CLabel
                      htmlFor="delivery_charge"
                      style={{ fontWeight: "bold" }}
                    >
                      Phí giao hàng
                    </CLabel>
                    <CFormGroup row>
                      <CCol>
                        <CInputGroup>
                          <CInput
                            defaultValue={
                              order
                                ? parseInt(order.delivery_charge) / 1000
                                : ""
                            }
                            name="delivery_charge"
                            placeholder={textPlaceHolder}
                            type="number"
                            maxLength="4"
                            onInput={maxLengthCheck}
                            required
                          />
                          <CInputGroupAppend>
                            <CInputGroupText>.000</CInputGroupText>
                          </CInputGroupAppend>
                          <CInvalidFeedback>{textPlaceHolder}</CInvalidFeedback>
                        </CInputGroup>
                      </CCol>
                    </CFormGroup>
                  </CFormGroup>
                </CCol>
              </CRow>

              <CFormGroup row className="my-0">
                <CCol xs="4">
                  <CFormGroup>
                    <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                      (*) Số điện thoại nhận
                    </CLabel>
                    <CInput
                      defaultValue={order ? formatPhone(order.phone) : ""}
                      name="phone"
                      placeholder={textPlaceHolder}
                      type="number"
                      maxLength={2}
                      required
                    />
                    <CInvalidFeedback>{textPlaceHolder}</CInvalidFeedback>
                  </CFormGroup>
                </CCol>
                <CCol xs="4">
                  <CFormGroup>
                    <CLabel htmlFor="name_user" style={{ fontWeight: "bold" }}>
                      Tên người nhận
                    </CLabel>
                    <CInput
                      defaultValue={order ? order.name_user : ""}
                      name="name_user"
                      placeholder={textPlaceHolder}
                    />
                  </CFormGroup>
                </CCol>
                <CCol xs="4">
                  <CFormGroup>
                    <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                      (*) Tài xế giao
                    </CLabel>
                    <CSelect
                      custom
                      name="select_admin"
                      value={selectedOptionDelivery.user_id}
                      onChange={(event) => {
                        const userIDSelected = parseInt(event.target.value);
                        const thisDelivery = deliveryDataSelectsRedux.find(
                          (item) => item.user_id === userIDSelected
                        );
                        setSelectedOptionDelivery(thisDelivery);
                      }}
                    >
                      {deliveryDataSelectsRedux.map((item, index) => (
                        <option value={item.user_id} key={index}>
                          {index !== 0
                            ? `${item.code} - ${item.user.name}`
                            : `${item.user.name}`}
                        </option>
                      ))}
                    </CSelect>
                  </CFormGroup>
                </CCol>
              </CFormGroup>

              <CFormGroup>
                <CLabel htmlFor="note" style={{ fontWeight: "bold" }}>
                  Ghi chú, dặn dò cho tài xế
                </CLabel>
                <CTextarea
                  defaultValue={order ? order.note : ""}
                  name="note"
                  rows="2"
                  placeholder="Viết lời ghi chú, dặn dò cho tài xế (nếu có)"
                />
              </CFormGroup>
              <CFormGroup row className="my-0">
                <CCol xs="6"></CCol>
              </CFormGroup>
            </CCardBody>
            {/* </CCard> */}
          </CForm>
        </div>
      </CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={handleCloseModal}>
          Đóng
        </CButton>
        <CButton
          disabled={isLoadingSubmit}
          color={"success"}
          form={ID_FORM}
          type={"submit"}
        >
          {isLoadingSubmit && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Nhập
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default RenderModalInputOrderFinish;
