const initialState = {
  is_active: false,
};

const surchargeServiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ACTIVE_SURCHARGE_SERVICE": {
      return {
        ...state,
        is_active: action.payload,
      };
    }

    default:
      return state;
  }
};

export default surchargeServiceReducer;
