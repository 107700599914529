import { combineReducers } from "redux";
import userReducer from "./user";
import authenticationReducer from "./authentication";
import logAdminLittleReducer from "./logAdminLittle";
import logDeliveryLittleReducer from "./logDeliveryLittle";
import runningDeliveryyReducer from "./runningDelivery";
import alertReducer from "./alert";
import listAdminReducer from "./listAdmin";
import restaurantsReducer from "./restaurants";
import restaurantCategoriesReducer from "./restaurantCategories";
import pageRouteReducer from "./pageRoute";
import routeCurrentReducer from "./routeCurrent";
import searchOrderReducer from "./searchOrder";
import customersReducer from "./customers";
import toastsReducer from "./toasts";
import isPartnersReducer from "./isPartners";

import listRestaurantOptionReducer from "./listRestaurantOption";

import newOrderReducer from "./orders/newOrder";
import receivedOrderReducer from "./orders/receivedOrder";
import finishOrderReducer from "./orders/finishOrder";
import cancelOrderReducer from "./orders/cancelOrder";
import pointOrderReducer from "./orders/pointOrder";

import imageViewerReducer from "./imageViewer";

import areaReducer from "./area";

import modalFormOrderReducer from "./modalFormOrder";
import modalInputOrderFinishReducer from "./modalInputOrderFinish";
import modalSurchargeServiceReducer from "./global/modal/modalSurchargeService";
import modalMapDeliveryReducer from "./global/modal/modalMapDelivery";

import deliveryDataSelectsReducer from "./delivery/dataSelects";

import surchargeServiceReducer from "./surchargeService";

import trackingLocationDeliveryReducer from "./tracking_location_delivery";

import responsiveReducer from "./responsive";
import modalDeliveryStatusReducer from "./modalDeliveryStatus";

const rootReducer = combineReducers({
  user: userReducer,
  responsive: responsiveReducer,
  authentication: authenticationReducer,
  logAdminLittle: logAdminLittleReducer,
  logDeliveryLittle: logDeliveryLittleReducer,
  runningDelivery: runningDeliveryyReducer,
  alert: alertReducer,
  listAdmin: listAdminReducer,
  restaurants: restaurantsReducer,
  restaurantCategories: restaurantCategoriesReducer,
  pageRoute: pageRouteReducer,
  routeCurrent: routeCurrentReducer,
  searchOrder: searchOrderReducer,
  customers: customersReducer,
  toasts: toastsReducer,
  isPartners: isPartnersReducer,
  listRestaurantOption: listRestaurantOptionReducer,

  newOrder: newOrderReducer,
  receivedOrder: receivedOrderReducer,
  finishOrder: finishOrderReducer,
  cancelOrder: cancelOrderReducer,
  pointOrder: pointOrderReducer,

  imageViewer: imageViewerReducer,
  area: areaReducer,

  modalFormOrder: modalFormOrderReducer,
  modalInputOrderFinish: modalInputOrderFinishReducer,
  modalSurchargeService: modalSurchargeServiceReducer,
  modalMapDelivery: modalMapDeliveryReducer,
  deliveryDataSelects: deliveryDataSelectsReducer,
  surchargeService: surchargeServiceReducer,
  trackingLocationDelivery: trackingLocationDeliveryReducer,
  modalDeliveryStatus: modalDeliveryStatusReducer
});

export default rootReducer;
