export const updatePointOrder = order => ({
  type: 'UPDATE_POINT_ORDER',
  payload: order,
});

export const pushBeforePointOrder = order => ({
  type: 'PUSH_BEFORE_POINT_ORDER',
  payload: order,
});

export const removeItemPointOrder = order_id => ({
  type: 'REMOVE_ITEM_POINT_ORDER',
  payload: order_id,
});

