import React, { useEffect, useState } from "react";
import {
  CButton,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CLabel,
  CModalBody,
  CModalFooter,
  CRow,
  CSpinner,
  CTextarea,
} from "@coreui/react";
import { ID_FORM_ORDER } from "../RenderModalFormOrder";
import axios from "axios";
import { apis } from "src/constants";
import refreshToken from "src/token/refreshToken";
import {
  formatPhone,
  formatPhoneForSave,
  maxLengthCheck,
} from "src/functions/baseFunction";
import Select from "react-select";
import { getOptionListAdmin } from "src/functions/getData";
import { useSelector } from "react-redux";

const FormOrderLaundry = ({ data, handleCloseModal }) => {
  const listAdminRedux = useSelector((state) => state.listAdmin.data);

  const [validated, setValidated] = useState(false);
  const [order, setOrder] = useState(null);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const optionsAdmin = getOptionListAdmin(listAdminRedux);

  useEffect(() => {
    if (data.admin_id) {
      setSelectedAdmins(JSON.parse(data.admin_id));
    }

    setOrder(data);
  }, []);

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingSubmit(true);
      await updateInfo(event);
      setLoadingSubmit(false);
    }
    setValidated(true);
  };

  const updateInfo = async (event) => {
    const {
      name_user,
      phone,
      delivery_charge,
      note,
      note_private,
      address_start,
      address_end,
      coordinates_start,
      coordinates_end,
      weight,
      laundry_fee,
    } = event.target.elements;

    const body = {
      name_user: name_user.value,
      phone: formatPhoneForSave(phone.value),
      delivery_charge: delivery_charge.value
        ? parseFloat(delivery_charge.value) * 1000
        : 0,
      note: note.value,
      note_private: note_private.value,
      address_start: address_start.value,
      address_end: address_end.value,
      coordinates_start: coordinates_start.value,
      coordinates_end: coordinates_end.value,
      weight: weight.value,
      laundry_fee: laundry_fee.value,
      admin_id: JSON.stringify(selectedAdmins),
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.put(
        apis.apiOrder + `admin/update-info/laundry/${order.id}`,
        body,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      handleCloseModal();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await updateInfo(event);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  return (
    <div>
      <CModalBody>
        <CForm
          onSubmit={handleSubmitForm}
          id={ID_FORM_ORDER}
          noValidate
          wasValidated={validated}
          autoComplete="off"
        >
          <CFormGroup row>
            <CCol md="12">
              <CLabel htmlFor="delivery_charge" style={{ fontWeight: "bold" }}>
                Nhân viên trực
              </CLabel>
              <Select
                isMulti
                placeholder="Chọn nhân viên trực"
                options={optionsAdmin}
                value={optionsAdmin.filter((obj) =>
                  selectedAdmins.includes(obj.value)
                )}
                onChange={(e) =>
                  setSelectedAdmins(
                    Array.isArray(e) ? e.map((x) => x.value) : []
                  )
                }
                // isDisabled={type === TYPE_EDIT ? true : false}
              />
            </CCol>
          </CFormGroup>
          <CRow>
            <CCol xs={"8"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Địa chỉ lấy đồ
                </CLabel>
                <CTextarea
                  defaultValue={
                    order ? order.service_laundry.address_start : ""
                  }
                  name="address_start"
                  rows="3"
                  required
                />
              </CFormGroup>
            </CCol>
            <CCol xs={"4"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Toạ độ
                </CLabel>
                <CInput
                  defaultValue={
                    order
                      ? `${order.service_laundry.latitude_start}, ${order.service_laundry.longitude_start}`
                      : ""
                  }
                  name="coordinates_start"
                  required
                />
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs={"8"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Địa chỉ trả đồ
                </CLabel>
                <CTextarea
                  defaultValue={order ? order.service_laundry.address_end : ""}
                  name="address_end"
                  rows="3"
                  required
                />
              </CFormGroup>
            </CCol>
            <CCol xs={"4"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Toạ độ
                </CLabel>
                <CInput
                  defaultValue={
                    order
                      ? `${order.service_laundry.latitude_end}, ${order.service_laundry.longitude_end}`
                      : ""
                  }
                  name="coordinates_end"
                  required
                />
              </CFormGroup>
            </CCol>
          </CRow>

          <CRow>
            <CCol xs={"4"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Trọng lượng đồ
                </CLabel>
                <CInput
                  defaultValue={order ? order.service_laundry.weight : ""}
                  name="weight"
                />
              </CFormGroup>
            </CCol>
            <CCol xs={"4"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Phí giặt đồ
                </CLabel>
                <CInput
                  defaultValue={
                    order?.service_laundry?.laundry_fee
                      ? order.service_laundry.laundry_fee.substring(
                          0,
                          order.service_laundry.laundry_fee.length - 3
                        )
                      : ""
                  }
                  name="laundry_fee"
                  type="number"
                />
              </CFormGroup>
            </CCol>
            <CCol xs={"4"}>
              <CFormGroup>
                <CLabel htmlFor="address" style={{ fontWeight: "bold" }}>
                  Phí giao đồ
                </CLabel>
                <CFormGroup row>
                  <CCol>
                    <CInputGroup>
                      <CInput
                        defaultValue={
                          order ? parseInt(order.delivery_charge) / 1000 : ""
                        }
                        name="delivery_charge"
                        type="number"
                        maxLength="4"
                        onInput={maxLengthCheck}
                        onWheel={(e) => e.target.blur()}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>.000</CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CCol>
                </CFormGroup>
              </CFormGroup>
            </CCol>
          </CRow>

          <CFormGroup row className="my-0">
            <CCol xs="6">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-end",
                }}
              >
                <div style={{ flex: 1 }}>
                  <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                    SĐT nhận hàng
                  </CLabel>
                  <CInput
                    defaultValue={order ? formatPhone(order.phone) : ""}
                    name="phone"
                    type="number"
                    required
                  />
                </div>
              </div>
            </CCol>
            <CCol xs="6">
              <CFormGroup>
                <CLabel htmlFor="name_user" style={{ fontWeight: "bold" }}>
                  Tên người nhận hàng
                </CLabel>
                <CInput
                  defaultValue={order ? order.name_user : ""}
                  name="name_user"
                  required
                />
              </CFormGroup>
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs="6">
              <CLabel htmlFor="note" style={{ fontWeight: "bold" }}>
                Ghi chú, dặn dò cho tài xế
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note : ""}
                name="note"
                rows="2"
                placeholder={"Viết lời ghi chú, dặn dò cho tài xế (nếu có)"}
              />
            </CCol>
            <CCol xs="6">
              <CLabel htmlFor="note_private" style={{ fontWeight: "bold" }}>
                Ghi chú riêng tư
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note_private : ""}
                name="note_private"
                rows="2"
                placeholder="Ghi chú riêng tư chỉ hiển thị cho văn phòng"
              />
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={handleCloseModal}>
          Đóng
        </CButton>
        <CButton
          disabled={isLoadingSubmit}
          color={"success"}
          form={ID_FORM_ORDER}
          type={"submit"}
        >
          {isLoadingSubmit && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Cập nhật
        </CButton>
      </CModalFooter>
    </div>
  );
};

export default FormOrderLaundry;
