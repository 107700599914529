const initialState = {
  visible: false,
};

const modalInputOrderFinishReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MODAL_INPUT_ORDER_FINISH": {
      return {
        visible: true,
      };
    }

    case "CLOSE_MODAL_INPUT_ORDER_FINISH": {
      return {
        visible: false,
      };
    }

    default:
      return state;
  }
};

export default modalInputOrderFinishReducer;
