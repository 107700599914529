import React, { useEffect, useState } from "react";

import { CModal, CModalHeader, CModalTitle, CSpinner } from "@coreui/react";
import axios from "axios";
import { SIZES, apis } from "src/constants";
import refreshToken from "src/token/refreshToken";
import "../../../../App.css";
import { useDispatch, useSelector } from "react-redux";
import { closeModalFormOrder } from "src/redux/actions/modalFormOrder";
import FormOrderDefault from "./type_order/Default";
import FormOrderLaundry from "./type_order/Laundry";
import FormOrderFlash from "./type_order/Flash";
import FormOrderNormal from "./type_order/Normal";
import FormOrderDelivery from "./type_order/Delivery";
import FormOrderGrab from "./type_order/Grab";
import FormOrderTaxi from "./type_order/Taxi";
import FormOrderOther from "./type_order/Other";

export const MODAL_FORM_ORDER_TYPE_CREATE = "create";
export const MODAL_FORM_ORDER_TYPE_EDIT = "edit";

export const ID_FORM_ORDER = "MODAL_FORM_ORDER";

export const getOptionsPartner = (list) => {
  return list.map((item) => {
    return {
      value: item.id,
      label: item.name,
      commission_rate: item.commission_rate,
      image_menu: item.image_menu,
    };
  });
};

const RenderModalFormOrder = () => {
  const dispatch = useDispatch();

  const { visible, data_handle } = useSelector((state) => state.modalFormOrder);
  const { order_id } = data_handle;

  const [dataOrder, setDataOrder] = useState(null); // {order, type_order}

  const type = order_id
    ? MODAL_FORM_ORDER_TYPE_EDIT
    : MODAL_FORM_ORDER_TYPE_CREATE;

  useEffect(() => {
    if (visible) {
      if (order_id) {
        fetchGetDataFormOrder();
      }
    } else {
      setTimeout(() => {
        setDataOrder(null);
      }, 500);
    }
  }, [visible]);

  const fetchGetDataFormOrder = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(
        apis.apiOrder + `admin/get-form-order/${order_id}`,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      setDataOrder(response.data);
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchGetDataFormOrder();
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(closeModalFormOrder());
    if (type === MODAL_FORM_ORDER_TYPE_EDIT) {
      const element = document.getElementById(ID_FORM_ORDER);
      if (element) {
        element.reset();
      }
    }
  };

  function renderMain() {
    const type_order_id = dataOrder?.type_order.id;

    if (
      type === MODAL_FORM_ORDER_TYPE_CREATE ||
      [100, 2].includes(type_order_id)
    ) {
      return (
        <FormOrderDefault
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
          type={type}
        />
      );
    }

    if (!dataOrder?.type_order) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: SIZES.height * 0.5,
          }}
        >
          <CSpinner color="primary" size="sm" />
        </div>
      );
    }

    if ([1, 9, 10].includes(type_order_id)) {
      return (
        <FormOrderNormal
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
          type_order_id={type_order_id}
        />
      );
    } else if ([2, 20].includes(type_order_id)) {
      return (
        <FormOrderFlash
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
        />
      );
    } else if (type_order_id === 3) {
      return (
        <FormOrderDelivery
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
        />
      );
    } else if (type_order_id === 4) {
      return (
        <FormOrderGrab
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
        />
      );
    } else if (type_order_id === 5) {
      return (
        <FormOrderTaxi
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
        />
      );
    } else if (type_order_id === 13) {
      return (
        <FormOrderLaundry
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
        />
      );
    } else {
      return (
        <FormOrderOther
          data={dataOrder?.order}
          handleCloseModal={handleCloseModal}
        />
      );
    }
  }

  return (
    <CModal
      show={visible}
      onClose={handleCloseModal}
      color={"success"}
      style={{ width: 800, zIndex: 100 }}
    >
      <CModalHeader closeButton>
        <CModalTitle>
          {type === MODAL_FORM_ORDER_TYPE_CREATE
            ? "Tạo đơn"
            : `Cập nhật đơn ${
                dataOrder?.type_order ? `- ${dataOrder.type_order.name}` : ""
              }`}
        </CModalTitle>
      </CModalHeader>
      {renderMain()}
    </CModal>
  );
};

export default RenderModalFormOrder;
