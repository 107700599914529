import React from "react";

import { CCol, CSpinner } from "@coreui/react";

const Loader = () => {
  return (
    <CCol
      style={{
        flex: 1,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <CSpinner color="success" size="xxl" />
    </CCol>
  );
};

export default Loader;
