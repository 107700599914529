const initialState = {
  data: [],
};

const restaurantCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RESTAURANT_CATEGORIES": {
      return {
        data: action.payload,
      };
    }

    case "EDIT_ITEM_RESTAURANT_CATEGORIES": {
      const dataState = state.data;
      const payload = action.payload;

      return {
        data: dataState.map((el) =>
          el.id === parseInt(payload.id) ? { ...el, ...payload } : el
        ),
      };
    }

    case "PUSH_BEFORE_RESTAURANT_CATEGORIES": {
      return {
        data: [action.payload, ...state.data],
      };
    }

    case "REMOVE_ITEM_RESTAURANT_CATEGORIES": {
      return {
        data: state.data.filter((el) => el.id !== action.payload),
      };
    }

    default:
      return state;
  }
};

export default restaurantCategoriesReducer;
