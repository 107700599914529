const initialState = {
  data: [],
};

const areaReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INIT_AREA": {
      return {
        data: action.payload,
      };
    }

    default:
      return state;
  }
};

export default areaReducer;
