import { freeSet } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { COLORS, type_animation } from "src/constants";
import { showModalSurchargeService } from "src/redux/actions/global/modal/modalSurchargeService";
import { showModalFormOrder } from "src/redux/actions/modalFormOrder";
import { showModalInputOrderFinish } from "src/redux/actions/modalInputOrderFinish";
import RenderModalSurchargeService from "src/views/global/modal/RenderModalSurchargeService";
import MyImageViewer from "src/views/utils/MyImageViewer";
import { TheContent, TheSidebar, TheHeader } from "./index";

import Radium, { StyleRoot } from "radium";

import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import RenderModalFormOrder from "src/views/global/modal/update_order/RenderModalFormOrder";
import RenderModalInputOrderFinish from "src/views/global/modal/RenderModalInputOrderFinish";
import RenderModalMapDelivery from "src/views/global/modal/RenderModalMapDelivery";
import { showModalMapDelivery } from "src/redux/actions/global/modal/modalMapDelivery";
import { showModalDeliveryStatus } from "src/redux/actions/modalDeliveryStatus";
import MediaQuery from "react-responsive";

const ComponentButton = ({
  bgColor,
  contentIcon,
  nameButton,
  handleOnClick,
}) => {
  return (
    <button
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: 65,
        height: 65,
        borderRadius: 15,
        border: "0px",
        backgroundColor: bgColor,
        boxShadow: "2px 5px 10px #1E1F20",
        padding: 0,
        marginTop: 10,
      }}
      onClick={handleOnClick}
    >
      <CIcon
        content={contentIcon}
        style={{ color: COLORS.white, marginBottom: 7 }}
      />
      <div style={{ fontWeight: "bold", color: COLORS.white, fontSize: 10 }}>
        {nameButton}
      </div>
    </button>
  );
};

const ButtonFixed = () => {
  const dispatch = useDispatch();

  const [isShow, setShow] = useState(true);
  const [typeAnimation, setTypeAnimation] = useState(
    type_animation.typeBounceInRight
  );

  const secondAnimation = 1;

  useEffect(() => {
    if (typeAnimation.key === type_animation.typeBounceOutRight.key) {
      setTimeout(() => {
        setShow(false);
      }, secondAnimation * 1000);
    } else if (typeAnimation.key === type_animation.typeBounceInRight.key) {
      setShow(true);
    }
  }, [typeAnimation]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        position: "fixed",
        bottom: 10,
        right: 5,
        width: 65,
        marginBottom: 10,
      }}
    >
      {isShow && (
        <StyleRoot>
          <div
            style={{
              animation: `x ${secondAnimation}s`,
              animationName: Radium.keyframes(
                typeAnimation.val,
                typeAnimation.key
              ),
            }}
          >
            <ComponentButton
              bgColor={COLORS.mainComponent}
              contentIcon={freeSet.cilPenAlt}
              nameButton={"Tạo đơn"}
              handleOnClick={() =>
                dispatch(
                  showModalFormOrder({
                    data_handle: {
                      order_id: null,
                    },
                  })
                )
              }
            />
            <ComponentButton
              bgColor={"#0165F7"}
              contentIcon={freeSet.cilKeyboard}
              nameButton={"Nhập đơn"}
              handleOnClick={() => dispatch(showModalInputOrderFinish())}
            />
            <ComponentButton
              bgColor={"#FE8C03"}
              contentIcon={freeSet.cilArrowCircleTop}
              nameButton={"Phụ thu"}
              handleOnClick={() => dispatch(showModalSurchargeService())}
            />
            <ComponentButton
              bgColor={"#E24134"}
              contentIcon={freeSet.cilMap}
              nameButton={"Bản đồ"}
              handleOnClick={() => dispatch(showModalMapDelivery())}
            />
            <MediaQuery maxWidth={767}>
              <ComponentButton
                bgColor={COLORS.grey4}
                contentIcon={freeSet.cilPeople}
                nameButton={"Danh sách tài"}
                handleOnClick={() => dispatch(showModalDeliveryStatus())}
              />
            </MediaQuery>
          </div>
        </StyleRoot>
      )}

      <button
        style={{
          display: "flex",
          flexDirection: "column",
          alignSelf: "center",
          alignItems: "center",
          justifyContent: "center",
          width: 30,
          height: 30,
          borderRadius: 15,
          border: "0px",
          backgroundColor: "#7e8387",
          boxShadow: "2px 5px 10px #1E1F20",
          padding: 0,
          marginTop: 10,
        }}
        onClick={() =>
          setTypeAnimation(
            isShow
              ? type_animation.typeBounceOutRight
              : type_animation.typeBounceInRight
          )
        }
      >
        {typeAnimation.key === type_animation.typeBounceInRight.key ? (
          <FaArrowRight color={COLORS.white} />
        ) : (
          <FaArrowLeft color={COLORS.white} />
        )}
      </button>
    </div>
  );
};

const TheLayout = () => {
  return (
    <div className="c-app c-default-layout">
      <TheSidebar />
      <div className="c-wrapper">
        <TheHeader />
        <div className="c-body" style={{ backgroundColor: COLORS.white }}>
          <TheContent />
        </div>
        {/* <TheFooter/> */}
      </div>
      <ButtonFixed />
      <RenderModalFormOrder />
      <RenderModalInputOrderFinish />
      <RenderModalSurchargeService />
      <RenderModalMapDelivery />
      <MyImageViewer />
    </div>
  );
};

export default TheLayout;
