const initialState = {
  data: {
    type: 0,
    text_input: "",
    select_value: 0,
    date: {
      day: new Date().getDate(),
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
    },
    list_order: [],
  },
};

const searchOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_SEARCH_ORDER": {

      return {
        data: {...state.data, ...action.payload},
      };
    }

    default:
      return state;
  }
};

export default searchOrderReducer;
