const initialState = {
  data: [],
}

const listAdminReducer = (state = initialState, action) => {
  switch (action.type) {
      case 'UPDATE_LIST_ADMIN': {
          return {
              data: action.payload,
          }
      }

      default:
          return state;
  }
}

export default listAdminReducer;
