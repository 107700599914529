const initialState = {
  data: [],
};

const trackingLocationDeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_TRACKING_LOCATION_DELIVEERY": {
      return {
        data: action.payload,
      };
    }

    default:
      return state;
  }
};

export default trackingLocationDeliveryReducer;
