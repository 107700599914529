export const updateReceivedOrder = (order) => ({
  type: "UPDATE_RECEIVED_ORDER",
  payload: order,
});

export const editItemReceivedOrder = order => ({
  type: 'EDIT_ITEM_RECEIVED_ORDER',
  payload: order,
});

export const pushBeforeReceivedOrder = (order) => ({
  type: "PUSH_BEFORE_RECEIVED_ORDER",
  payload: order,
});

export const removeItemReceivedOrder = (order_id) => ({
  type: "REMOVE_ITEM_RECEIVED_ORDER",
  payload: order_id,
});
