import React from "react";
import { useDispatch, useSelector } from "react-redux";

import ImageViewer from "react-simple-image-viewer";
import { closeImageViewer } from "src/redux/actions/imageViewer";
import { CModal } from "@coreui/react";

const MyImageViewer = () => {
  const dispatch = useDispatch();

  const { url, indexCurrent, isOpen } = useSelector(
    (state) => state.imageViewer
  );

  const handleCloseImageViewer = () => {
    dispatch(closeImageViewer());
  };

  if (!isOpen) {
    return null;
  }

  return (
    <CModal show={isOpen}>
      <ImageViewer
        src={url}
        currentIndex={indexCurrent}
        disableScroll={false}
        closeOnClickOutside={true}
        onClose={handleCloseImageViewer}
      />
    </CModal>
  );
};
export default MyImageViewer;
