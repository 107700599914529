const initialState = {
  sidebarShow: "responsive",
};

const responsiveReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_RESPONSIVE": {
      return {
        ...state,
        sidebarShow: action.payload,
      };
    }

    default:
      return state;
  }
};

export default responsiveReducer;
