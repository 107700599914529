const initialState = {
  data: [],
};

const pointOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_POINT_ORDER": {
      return {
        data: action.payload,
      };
    }

    case "PUSH_BEFORE_POINT_ORDER": {
      const data = state.data;
      const payload = action.payload;

      const indexItem = data.findIndex((i) => i.id === payload.id);

      if (indexItem !== -1) {
        const newData = [payload, ...data.filter((el) => el.id !== payload.id)];
        return { data: newData };
      }

      return {
        data: [payload, ...data],
      };
    }

    case "REMOVE_ITEM_POINT_ORDER": {
      return {
        data: state.data.filter((item) => item.id !== action.payload),
      };
    }

    default:
      return state;
  }
};

export default pointOrderReducer;
