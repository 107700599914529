// const LOCALHOST = "http://192.168.1.4:3000";

const LOCALHOST = "https://server.iqi.com.vn";

const apiUser = LOCALHOST + "/users/";
const apiOrder = LOCALHOST + "/orders/";
const apiOrderDelivery = LOCALHOST + "/order-deliverys/";
const apiDelivery = LOCALHOST + "/deliverys/";
const apiRestaurant = LOCALHOST + "/restaurants/";
const apiLogAdmin = LOCALHOST + "/log-admin/";
const apiLogDelivery = LOCALHOST + "/log-delivery/";
const apiRestaurantItem = LOCALHOST + "/restaurant-items/";
const apiOrderRestaurant = LOCALHOST + "/order-restaurants/";
const apiRestaurantCollection = LOCALHOST + "/restaurant-collection/";
const apiRestaurantCategory = LOCALHOST + "/restaurant-category/";
const apiNews = LOCALHOST + "/news/";
const apiUtilities = LOCALHOST + "/utilities/";
const apiTypeUtilities = LOCALHOST + "/type-utilities/";
const apiTextForMobile = LOCALHOST + "/text-for-mobile/";
const apiRestaurantUser = LOCALHOST + "/restaurant-users/";
const apiRechargeHistoryDelivery = LOCALHOST + "/recharge-history-delivery/";
const apiPaybookHistoryDelivery = LOCALHOST + "/paybook-history-delivery/";
const apiSetting = LOCALHOST + "/setting/";
const apiOrderPointDelivery = LOCALHOST + "/order-point-delivery/";
const apiNotificationDelivery = LOCALHOST + "/notification-delivery/";
const apiNotificationCustomer = LOCALHOST + "/notification-customer/";
const apiItemCategory = LOCALHOST + "/item-category/";
const apiDeliveryRunning = LOCALHOST + "/delivery-running/";
const apiPromoCode = LOCALHOST + "/promo-code/";
const apiPromoType = LOCALHOST + "/promo-type/";
const apiPromoLimit = LOCALHOST + "/promo-limit/";
const apiPromoRestaurant = LOCALHOST + "/promo-restaurant/";
const apiRestaurantRequest = LOCALHOST + "/restaurant-request/";
const apiDashboard = LOCALHOST + "/dashboard/";
const apiExcel = LOCALHOST + "/excel/download/";
const apiAppCustomer = LOCALHOST + "/app/customer/";
const apiAdvertiseCustomer = LOCALHOST + "/ad-customer/";
const apiNotificationCalendar = LOCALHOST + "/notification-calendar/";
const apiArea = LOCALHOST + "/area/";
const apiTaxiModel = LOCALHOST + "/taxi-model/";
const apiVoucherLimit = LOCALHOST + "/voucher-limit/";
const apiVoucherDelivery = LOCALHOST + "/voucher-delivery/";
const apiVoucherGrab = LOCALHOST + "/voucher-grab/";
const apiVoucherTaxi = LOCALHOST + "/voucher-taxi/";
const apiSurchargeService = LOCALHOST + "/surcharge-service/";
const apiTypeOrder = LOCALHOST + "/type-order/";
const apiCarContract = LOCALHOST + "/car-contract/";
const apiCarContractItem = LOCALHOST + "/car-contract-item/";
const apiCarContractDriver = LOCALHOST + "/car-contract-driver/";
const apiServiceTrainTicketPassenger = LOCALHOST + "/service-train-ticket-passenger/";
const apiMessageBrand = LOCALHOST + "/massage-brand/";
const apiHotel = LOCALHOST + "/hotel/";
const apiHotelRoom = LOCALHOST + "/hotel-room/";
const apiDrugstore = LOCALHOST + "/drugstore/";
const apiEvents = LOCALHOST + "/events/";

// làm tạm
const apiTemp = LOCALHOST + "/temp/";

const exportedObject = {
  LOCALHOST,
  apiUser,
  apiOrder,
  apiOrderDelivery,
  apiDelivery,
  apiRestaurant,
  apiLogAdmin,
  apiLogDelivery,
  apiRestaurantItem,
  apiOrderRestaurant,
  apiRestaurantCollection,
  apiRestaurantCategory,
  apiNews,
  apiUtilities,
  apiTypeUtilities,
  apiTextForMobile,
  apiRestaurantUser,
  apiDashboard,
  apiExcel,
  apiRechargeHistoryDelivery,
  apiPaybookHistoryDelivery,
  apiSetting,
  apiOrderPointDelivery,
  apiNotificationDelivery,
  apiNotificationCustomer,
  apiItemCategory,
  apiAppCustomer,
  apiTemp,
  apiDeliveryRunning,
  apiPromoCode,
  apiPromoType,
  apiPromoLimit,
  apiPromoRestaurant,
  apiRestaurantRequest,
  apiAdvertiseCustomer,
  apiNotificationCalendar,
  apiArea,
  apiTaxiModel,
  apiVoucherLimit,
  apiVoucherDelivery,
  apiVoucherGrab,
  apiVoucherTaxi,
  apiSurchargeService,
  apiTypeOrder,
  apiCarContract,
  apiCarContractItem,
  apiCarContractDriver,
  apiServiceTrainTicketPassenger,
  apiMessageBrand,
  apiHotel,
  apiHotelRoom,
  apiDrugstore,
  apiEvents
};

export default exportedObject;
