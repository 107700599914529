import React from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { apis, SIZES } from "src/constants";
import { useSelector } from "react-redux";

const TheHeaderDropdownLogAdmin = () => {
  const logAdminLittleRedux = useSelector((state) => state.logAdminLittle.data);

  return (
    <CDropdown inNav className="c-header-nav-item mx-2" direction="down">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-envelope-open" />
        <CBadge shape="pill" color="info">
          !
        </CBadge>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light">
          <strong>Nhật kí văn phòng</strong>
        </CDropdownItem>

        {logAdminLittleRedux?.map((item) => (
          <CDropdownItem href="#" key={item.created_at}>
            <div className="message">
              <div className="pt-3 mr-3 float-left">
                <div className="c-avatar">
                  <CImg
                    src={`${apis.LOCALHOST}/assets/img/icons/avatar.png`}
                    className="c-avatar-img"
                    alt="admin@bootstrapmaster.com"
                  />
                  <span className="c-avatar-status bg-success"></span>
                </div>
              </div>
              <div style={{width: SIZES.width * 0.3}}>
                <small className="text-muted">{item.user.name}</small>
                <small className="text-muted float-right mt-1">
                  {item.created_at}
                </small>
              </div>
              <div className="text-truncate font-weight-bold">
                <span className="fa fa-exclamation text-danger"></span>{" "}
                {item.title}
              </div>
              <div
                className="small text-muted text-truncate"
                style={{width: SIZES.width * 0.2}}
                dangerouslySetInnerHTML={{ __html: item.body }}
              />
            </div>
          </CDropdownItem>
        ))}

        <CDropdownItem className="text-center border-top" to={"/log/admin/null"}>
          <strong>Xem tất cả</strong>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default TheHeaderDropdownLogAdmin;
