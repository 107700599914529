import lazyWithRetry from './lazyWithRetry';

const Dashboard = lazyWithRetry(() => import('./views/dashboard/Dashboard'));
const ListOrder = lazyWithRetry(() => import('./views/dashboard/childrent/ListOrder'));

const HomeTable = lazyWithRetry(() => import('./views/table/HomeTable'));
const TableAllOrder = lazyWithRetry(() => import('./views/table/TableAllOrder'));

const DetailOrder = lazyWithRetry(() => import('./views/table/details/DetailOrder'));

const WarningUserOrder = lazyWithRetry(() => import('./views/table/warning'));

const DeliveryManage = lazyWithRetry(() => import('./views/delivery/DeliveryManage'));
const DeliveryMap = lazyWithRetry(() => import('./views/delivery/map'));
const Revenue = lazyWithRetry(() => import('./views/delivery/childrents/Revenue'));
const OptionDelivery = lazyWithRetry(() => import('./views/delivery/options'));
const DetailDelivery = lazyWithRetry(() => import('./views/delivery/childrents/DetailDelivery'));
const RatingDelivery = lazyWithRetry(() => import('./views/delivery/options/rating'));
const AddDelivery = lazyWithRetry(() => import('./views/delivery/childrents/AddDelivery'));
const PayBookHistory = lazyWithRetry(() => import('./views/delivery/childrents/PayBookHistory'));
const RatingHistory = lazyWithRetry(() => import('./views/delivery/childrents/RatingHistory'));
const Notification = lazyWithRetry(() => import('./views/delivery/childrents/Notification'));

const PartnerManage = lazyWithRetry(() => import('./views/partner/PartnerManage'));
const AccountPartnerManage = lazyWithRetry(() => import('./views/partner/AccountPartnerManage'));
const UpdateUserPartner = lazyWithRetry(() => import('./views/partner/childrents/UpdateUserPartner'));
const PartnerWalletConsumptionManage = lazyWithRetry(() => import('./views/partner/wallet_consumption'));

const ApproveRequestPartnerManage = lazyWithRetry(() => import('./views/partner/request'));
const DetailRequestPartner = lazyWithRetry(() => import('./views/partner/request/detail'));


const Items = lazyWithRetry(() => import('./views/partner/childrents/item'));
const OptionsItem = lazyWithRetry(() => import('./views/partner/childrents/item/childrents/Options'));
const RevenuePartner = lazyWithRetry(() => import('./views/partner/childrents/revenue'));
const DetailOrderRestaurant = lazyWithRetry(() => import('./views/partner/childrents/revenue/detailOrderRestaurant'));
const DetailPartner = lazyWithRetry(() => import('./views/partner/childrents/detail'));


const ListRestaurantOfUser = lazyWithRetry(() => import('./views/partner/childrents/ListRestaurantOfUser'));
const ListUserOfRestaurant = lazyWithRetry(() => import('./views/partner/childrents/ListUserOfRestaurant'));
const CategoriesRestaurant = lazyWithRetry(() => import('./views/partner/childrents/categories'));
const SortCategories = lazyWithRetry(() => import('./views/partner/childrents/categories/sort'));
const RestaurantsOfCategory = lazyWithRetry(() => import('./views/partner/childrents/categories/restaurantsOfCategory'));

const CustomerManage = lazyWithRetry(() => import('./views/customer/CustomerManage'));
const NotificationCustomer = lazyWithRetry(() => import('./views/customer/childrents/notification'));
const NotificationCalendar = lazyWithRetry(() => import('./views/customer/childrents/notification_calendar'));

const Collection = lazyWithRetry(() => import('./views/partner/Collection'));

// const NewsManage = lazyWithRetry(() => import('./views/news/NewsManage'));
// const AddNews = lazyWithRetry(() => import('./views/news/childrents/AddNews'));

// const UtilitiesManage = lazyWithRetry(() => import('./views/utilities/UtilitiesManage'));

// const PromoManage = lazyWithRetry(() => import('./views/mobile/customer/promo'));
// const DetailPromoAll = lazyWithRetry(() => import('./views/mobile/customer/promo/detail'));

const AdvertiseCustomer = lazyWithRetry(() => import('./views/mobile/customer/advertise'));
const DetailAdvertiseCustomer = lazyWithRetry(() => import('./views/mobile/customer/advertise/detail'));

const SortRestaurantManage = lazyWithRetry(() => import('./views/mobile/customer/restaurant/sort'));
const CustomerEdit = lazyWithRetry(() => import('./views/mobile/childrents/CustomerEdit'));

const LogAdmin = lazyWithRetry(() => import('./views/log/LogAdmin'));
const LogDelivery = lazyWithRetry(() => import('./views/log/LogDelivery'));

const Profile = lazyWithRetry(() => import('./views/users/Profile'));
const AddUser = lazyWithRetry(() => import('./views/users/AddUser'));
const EditUser = lazyWithRetry(() => import('./views/users/EditUser'));
const ListAdmin = lazyWithRetry(() => import('./views/users/ListAdmin'));

const VoucherNormalManage = lazyWithRetry(() => import('./views/voucher/normal'));
const VoucherNormalEdit = lazyWithRetry(() => import('./views/voucher/normal/edit'));
const VoucherNormalReport = lazyWithRetry(() => import('./views/voucher/normal/report'));

const VoucherDeliveryManage = lazyWithRetry(() => import('./views/voucher/delivery'));
const VoucherDeliveryEdit = lazyWithRetry(() => import('./views/voucher/delivery/edit'));
const VoucherDeliveryReport = lazyWithRetry(() => import('./views/voucher/delivery/report'));

const VoucherGrabManage = lazyWithRetry(() => import('./views/voucher/grab'));
const VoucherGrabEdit = lazyWithRetry(() => import('./views/voucher/grab/edit'));
const VoucherGrabReport = lazyWithRetry(() => import('./views/voucher/grab/report'));

const VoucherTaxiManage = lazyWithRetry(() => import('./views/voucher/taxi'));
const VoucherTaxiEdit = lazyWithRetry(() => import('./views/voucher/taxi/edit'));
const VoucherTaxiReport = lazyWithRetry(() => import('./views/voucher/taxi/report'));

const CarContractManage = lazyWithRetry(() => import('./views/car_contract'));
const CarContractEdit = lazyWithRetry(() => import('./views/car_contract/edit'));
const CarContractItem = lazyWithRetry(() => import('./views/car_contract/items'));
const CarContractItemEdit = lazyWithRetry(() => import('./views/car_contract/items/edit'));

const CarContractDriverManage = lazyWithRetry(() => import('./views/car_contract/driver'));
const CarContractDriverEdit = lazyWithRetry(() => import('./views/car_contract/driver/edit'));

const MassageBrandManage = lazyWithRetry(() => import('./views/massage'));
const MassageBrandEdit = lazyWithRetry(() => import('./views/massage/edit'));

const HotelManage = lazyWithRetry(() => import('./views/hotel'));
const HotelEdit = lazyWithRetry(() => import('./views/hotel/edit'));
const HotelRoomEdit = lazyWithRetry(() => import('./views/hotel/edit/room/edit'));

const DrugstoreManage = lazyWithRetry(() => import('./views/drugstore'));
const DrugstoreEdit = lazyWithRetry(() => import('./views/drugstore/edit'));

const EventLottery = lazyWithRetry(() => import('./views/events/EventLottery'));

// const Setting = lazyWithRetry(() => import('./views/other/Setting'));


const routes = [
  // { path: '/', exact: true, name: 'Trang chủ' },
  { path: '/dashboard', exact: true, name: 'Trang chủ', component: Dashboard },
  { path: '/dashboard/list-order', exact: true, name: 'Danh sách đơn hàng', component: ListOrder },

  { path: '/detail-order/:order_id', exact: true, name: 'Chi tiết đơn hàng', component: DetailOrder },

  { path: '/table/home/', exact: true, name: 'Quản lí đơn hàng', component: HomeTable },

  { path: '/table/home/list-order-warning-user/:order_id', exact: true, name: 'Lịch sử huỷ đơn của khách', component: WarningUserOrder },

  { path: '/table/table-all-order', exact: true, name: 'Tìm kiếm đơn hàng', component: TableAllOrder },

  { path: '/delivery/manager/shipper', exact: true, name: 'Quản lý tài xế', component: DeliveryManage },
  { path: '/delivery/map', exact: true, name: 'Quản lý hành trình', component: DeliveryMap },
  { path: '/delivery/manager/options/:delivery_id', exact: true, name: 'Tuỳ chọn', component: OptionDelivery },
  { path: '/delivery/manager/info/:delivery_id', exact: true, name: 'Thông tin tài xế', component: DetailDelivery },
  { path: '/delivery/manager/rating/:delivery_id', exact: true, name: 'Đánh giá tài xế', component: RatingDelivery },
  { path: '/delivery/manager/add', exact: true, name: 'Thêm mới tài xế', component: AddDelivery },
  { path: '/delivery/manager/revenue/:delivery_id', exact: true, name: 'Thống kê đơn của tài xế', component: Revenue },
  { path: '/delivery/manager/paybook-history/:delivery_id', exact: true, name: 'Lịch sử sổ nợ', component: PayBookHistory },
  { path: '/delivery/manager/rating-history', exact: true, name: 'Lịch sử đánh giá', component: RatingHistory },
  { path: '/delivery/manager/notification', exact: true, name: 'Thông báo tài xế', component: Notification },

  { path: '/partner/manager', exact: true, name: 'Quản lý thông tin đối tác', component: PartnerManage },
  // { path: '/partner/manager/detail/:restaurant_id', exact: true, name: 'Thông tin quán ăn', component: DetailPartner },
  { path: '/partner/manager/items/:restaurant_id', exact: true, name: 'Danh sách món ăn', component: Items },
  { path: '/partner/manager/items/:restaurant_id/options/:item_id', exact: true, name: 'Danh sách nhóm lựa chọn', component: OptionsItem },
  { path: '/partner/manager/detail/:restaurant_id', exact: true, name: 'Thông tin đối tác', component: DetailPartner },

  { path: '/partner/manager/users/:restaurant_id', exact: true, name: 'Danh sách tài khoản quản lý quán ăn này', component: ListUserOfRestaurant },
  { path: '/partner/manager/categories', exact: true, name: 'Danh mục quán', component: CategoriesRestaurant },
  { path: '/partner/manager/categories/sort', exact: true, name: 'Sắp xếp danh mục', component: SortCategories },
  { path: '/partner/manager/categories/restaurants/:category_id', exact: true, name: 'Danh sách quán ăn thuộc danh mục', component: RestaurantsOfCategory },

  { path: '/partner/account', exact: true, name: 'Quản lý tài khoản đối tác', component: AccountPartnerManage },
  { path: '/partner/account/edit', exact: true, name: 'Chỉnh sửa tài khoản đối tác', component: UpdateUserPartner },
  { path: '/partner/account/restaurants/:user_id', exact: true, name: 'Danh sách quán ăn tài khoản này quản lý', component: ListRestaurantOfUser },

  { path: '/partner/request/:type', exact: true, name: 'Yêu cầu bên đối tác', component: ApproveRequestPartnerManage },
  { path: '/partner/request/:type/detail/:request_id', exact: true, name: 'Chi tiết yêu cầu', component: DetailRequestPartner },

  { path: '/partner/collection', exact: true, name: 'Chiết khấu quán ăn', component: Collection },
  { path: '/partner/collection/detail/:restaurant_id/:month/:year', exact: true, name: 'Chi tiết chiết khấu của quán', component: RevenuePartner },
  { path: '/partner/collection/detail/:restaurant_id/:month/:year/:order_restaurant_id', exact: true, name: 'Chi tiết đơn của quán', component: DetailOrderRestaurant },

  { path: '/partner/consumption', exact: true, name: 'Quản lý chủ quán tích luỹ đặt hàng', component: PartnerWalletConsumptionManage },

  { path: '/customer/manager', exact: true, name: 'Quản lý khách hàng', component: CustomerManage },
  { path: '/customer/manager/fullfull', exact: true, name: 'Quản lý khách hàng', component: CustomerManage },
  { path: '/customer/manager/notification', exact: true, name: 'Thông báo khách hàng', component: NotificationCustomer },
  { path: '/customer/manager/notification-calendar', exact: true, name: 'Danh sách lịch thông báo', component: NotificationCalendar },

  // { path: '/news/manager', exact: true, name: 'Quản lý tin tức', component: NewsManage },
  // { path: '/news/manager/add', exact: true, name: 'Thêm tin tức', component: AddNews },

  // { path: '/utilities/manager', exact: true, name: 'Quản lý tiện ích', component: UtilitiesManage },

  // { path: '/mobile/customer/manage/promo', exact: true, name: 'Quản lý khuyến mãi', component: PromoManage },
  // { path: '/mobile/customer/manage/promo/detail/:promo_restaurant_id', exact: true, name: 'Chi tiết khuyến mãi', component: DetailPromoAll },

  { path: '/mobile/customer/advertise/manage', exact: true, name: 'Quản lý quảng cáo app khách hàng', component: AdvertiseCustomer },
  { path: '/mobile/customer/advertise/manage/detail/:advertise_id', exact: true, name: 'Cập nhập quảng cáo', component: DetailAdvertiseCustomer },
  { path: '/mobile/customer/manage/restaurant/sort', exact: true, name: 'Danh sách sắp xếp quán ăn app khách hàng', component: SortRestaurantManage },
  { path: '/mobile/customer/manage/edit', exact: true, name: 'Sửa bài viết', component: CustomerEdit },

  { path: '/log/admin/:user_id', exact: true, name: 'Nhật kí văn phòng', component: LogAdmin },
  { path: '/log/delivery/:delivery_id', exact: true, name: 'Nhật kí tài xế', component: LogDelivery },

  { path: '/user/profile', exact: true, name: 'Thông tin tài khoản', component: Profile },
  { path: '/user/add', exact: true, name: 'Tạo tài khoản mới', component: AddUser },
  { path: '/user/edit', exact: true, name: 'Chỉnh sửa tài khoản mới', component: EditUser },
  { path: '/user/list', exact: true, name: 'Danh sách quản trị viên', component: ListAdmin },

  { path: '/voucher/normal/manage', exact: true, name: 'Voucher ăn uống', component: VoucherNormalManage },
  { path: '/voucher/normal/manage/edit/:voucher_code', exact: true, name: 'Cập nhật', component: VoucherNormalEdit },
  { path: '/voucher/normal/manage/report/:voucher_code', exact: true, name: 'Thống kê', component: VoucherNormalReport },

  { path: '/voucher/delivery/manage', exact: true, name: 'Voucher giao hàng', component: VoucherDeliveryManage },
  { path: '/voucher/delivery/manage/edit/:voucher_code', exact: true, name: 'Cập nhật', component: VoucherDeliveryEdit },
  { path: '/voucher/delivery/manage/report/:voucher_code', exact: true, name: 'Thống kê', component: VoucherDeliveryReport },

  { path: '/voucher/taxi/manage', exact: true, name: 'Voucher taxi', component: VoucherTaxiManage },
  { path: '/voucher/taxi/manage/edit/:voucher_code', exact: true, name: 'Cập nhật', component: VoucherTaxiEdit },
  { path: '/voucher/taxi/manage/report/:voucher_code', exact: true, name: 'Thống kê', component: VoucherTaxiReport },

  { path: '/voucher/grab/manage', exact: true, name: 'Voucher xe ôm', component: VoucherGrabManage },
  { path: '/voucher/grab/manage/edit/:voucher_code', exact: true, name: 'Cập nhật', component: VoucherGrabEdit },
  { path: '/voucher/grab/manage/report/:voucher_code', exact: true, name: 'Thống kê', component: VoucherGrabReport },

  { path: '/car-contract/manage', exact: true, name: 'Quản lý xe hợp đồng', component: CarContractManage },
  { path: '/car-contract/manage/edit/:car_contract_id', exact: true, name: 'Cập nhật thông tin nhà xe', component: CarContractEdit },
  { path: '/car-contract/manage/items/:car_contract_id', exact: true, name: 'Danh sách xe', component: CarContractItem },
  { path: '/car-contract/manage/items/:car_contract_id/edit/:car_contract_item_id', exact: true, name: 'Cập nhật thông tin xe', component: CarContractItemEdit },

  { path: '/car-contract/driver/manage', exact: true, name: 'Quản lý tài xế dịch vụ xe hợp đồng', component: CarContractDriverManage },
  { path: '/car-contract/driver/manage/edit/:car_contract_driver_id', exact: true, name: 'Cập nhật thông tin tài xế xe hợp đồng', component: CarContractDriverEdit },

  { path: '/massage/manage', exact: true, name: 'Quản lý massage', component: MassageBrandManage },
  { path: '/massage/manage/edit/:massage_brand_id', exact: true, name: 'Cập nhật thông tin đối tác massage', component: MassageBrandEdit },

  { path: '/hotel/manage', exact: true, name: 'Quản lý khách sạn, nhà nghỉ', component: HotelManage },
  { path: '/hotel/manage/edit/:hotel_id', exact: true, name: 'Cập nhật thông tin khách sạn, nhà nghỉ', component: HotelEdit },
  { path: '/hotel/manage/edit/:hotel_id/room/edit/:room_id', exact: true, name: 'Cập nhật thông tin loại phòng', component: HotelRoomEdit },

  { path: '/drugstore/manage', exact: true, name: 'Quản lý nhà thuốc', component: DrugstoreManage },
  { path: '/drugstore/manage/edit/:drugstore_id', exact: true, name: 'Cập nhật thông tin sản phẩm', component: DrugstoreEdit },

  { path: '/events/lottery', exact: true, name: 'Sự kiện xổ số', component: EventLottery },


  // { path: '/setting', exact: true, name: 'Cài đặt', component: Setting },

];

export default routes;
