import axios from "axios";
import { apis } from "src/constants";

const refreshToken = async (token) => {
    try {
      const response = await axios.post(
        apis.apiUser + 'refresh-token',
        {refreshToken: token.refreshToken},
        {
          headers: {
            verify: token.accessToken,
          },
        },
      );

      const newToken = {
        accessToken: response.data.accessToken,
        refreshToken: token.refreshToken,
      };
      localStorage.setItem('@loginData', JSON.stringify(newToken));
    } catch (err) {
      return localStorage.clear();
    }
  };

export default refreshToken;
