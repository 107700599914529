// import React, { useEffect, useState } from "react";
// import {
//   CButton,
//   CCard,
//   CCardBody,
//   CCardHeader,
//   CCol,
//   CForm,
//   CFormGroup,
//   CInput,
//   CInputGroup,
//   CInputGroupAppend,
//   CInputGroupText,
//   CLabel,
//   CModalBody,
//   CModalFooter,
//   CRow,
//   CSpinner,
//   CTextarea,
// } from "@coreui/react";
// import { ID_FORM_ORDER } from "../RenderModalFormOrder";
// import axios from "axios";
// import { apis } from "src/constants";
// import refreshToken from "src/token/refreshToken";
// import {
//   formatPhone,
//   formatPhoneForSave,
//   maxLengthCheck,
// } from "src/functions/baseFunction";
// import Select from "react-select";
// import { getOptionListAdmin } from "src/functions/getData";
// import { useSelector } from "react-redux";

// const FormOrderDelivery = ({ data, handleCloseModal }) => {
//   const listAdminRedux = useSelector((state) => state.listAdmin.data);

//   const [validated, setValidated] = useState(false);
//   const [order, setOrder] = useState(null);
//   const [isLoadingSubmit, setLoadingSubmit] = useState(false);
//   const [selectedAdmins, setSelectedAdmins] = useState([]);
//   const optionsAdmin = getOptionListAdmin(listAdminRedux);

//   console.log("order:", order);

//   useEffect(() => {
//     if (data.admin_id) {
//       setSelectedAdmins(JSON.parse(data.admin_id));
//     }

//     setOrder(data);
//   }, []);

//   const handleSubmitForm = async (event) => {
//     event.preventDefault();

//     const form = event.currentTarget;
//     if (form.checkValidity() === false) {
//       event.preventDefault();
//       event.stopPropagation();
//     } else {
//       setLoadingSubmit(true);
//       await updateInfo(event);
//       setLoadingSubmit(false);
//     }
//     setValidated(true);
//   };

//   const updateInfo = async (event) => {
//     const {
//       phone_start,
//       name_start,
//       address_start,
//       coordinates_start,

//       flash_detail_buy,

//       phone_end,
//       name_end,
//       address_end,
//       coordinates_end,

//       delivery_charge,
//       note,
//       note_private,
//     } = event.target.elements;

//     const body = {
//       name_start: name_start.value,
//       phone_start: formatPhoneForSave(phone_start.value),
//       address_start: address_start.value,
//       // coordinates_start: coordinates_start.value,

//       flash_detail_buy: flash_detail_buy.value,

//       // name_end: name_end.value,
//       // phone_end: phone_end.value,
//       // address_end: address_end.value,
//       // coordinates_end: coordinates_end.value,
//       delivery_charge: delivery_charge.value
//         ? parseFloat(delivery_charge.value) * 1000
//         : 0,
//       note: note.value,
//       note_private: note_private.value,
//       admin_id: JSON.stringify(selectedAdmins),
//     };

//     const userLocal = JSON.parse(localStorage.getItem("@loginData"));
//     try {
//       const response = await axios.put(
//         apis.apiOrder + `admin/update-info/delivery/${order.id}`,
//         body,
//         {
//           headers: {
//             verify: userLocal.accessToken,
//           },
//         }
//       );

//       alert(response.data);

//       handleCloseModal();
//     } catch (error) {
//       if (error.response?.status === 401) {
//         await refreshToken(userLocal);
//         await updateInfo(event);
//       } else if (error.response?.status === 400) {
//         alert(error.response.data);
//       } else {
//         alert(error.response.data);
//       }
//     }
//   };

//   return (
//     <div>
//       <CModalBody>
//         <CForm
//           onSubmit={handleSubmitForm}
//           id={ID_FORM_ORDER}
//           noValidate
//           wasValidated={validated}
//           autoComplete="off"
//         >
//           <CFormGroup row>
//             <CCol md="9">
//               <CLabel htmlFor="delivery_charge" style={{ fontWeight: "bold" }}>
//                 Nhân viên trực
//               </CLabel>
//               <Select
//                 isMulti
//                 placeholder="Chọn nhân viên trực"
//                 options={optionsAdmin}
//                 value={optionsAdmin.filter((obj) =>
//                   selectedAdmins.includes(obj.value)
//                 )}
//                 onChange={(e) =>
//                   setSelectedAdmins(
//                     Array.isArray(e) ? e.map((x) => x.value) : []
//                   )
//                 }
//                 // isDisabled={type === TYPE_EDIT ? true : false}
//               />
//             </CCol>
//             <CCol xs="3">
//               <CFormGroup>
//                 <CLabel
//                   htmlFor="delivery_charge"
//                   style={{ fontWeight: "bold" }}
//                 >
//                   Phí giao hàng
//                 </CLabel>
//                 <CFormGroup row>
//                   <CCol>
//                     <CInputGroup>
//                       <CInput
//                         defaultValue={
//                           order ? parseInt(order.delivery_charge) / 1000 : ""
//                         }
//                         name="delivery_charge"
//                         type="number"
//                         maxLength="4"
//                         onInput={maxLengthCheck}
//                         onWheel={(e) => e.target.blur()}
//                       />
//                       <CInputGroupAppend>
//                         <CInputGroupText>.000</CInputGroupText>
//                       </CInputGroupAppend>
//                     </CInputGroup>
//                   </CCol>
//                 </CFormGroup>
//               </CFormGroup>
//             </CCol>
//           </CFormGroup>
//           <CCard>
//             <CCardHeader>Thông tin điểm lấy hàng</CCardHeader>
//             <CCardBody style={{ paddingBottom: 0 }}>
//               <div>
//                 <CFormGroup row className="my-0">
//                   <CCol xs="6">
//                     <div
//                       style={{
//                         display: "flex",
//                         flexDirection: "row",
//                         alignItems: "flex-end",
//                       }}
//                     >
//                       <div style={{ flex: 1 }}>
//                         <CLabel
//                           htmlFor="phone_start"
//                           style={{ fontWeight: "bold" }}
//                         >
//                           SĐT liên hệ
//                         </CLabel>
//                         <CInput
//                           defaultValue={order ? formatPhone(order.phone) : ""}
//                           name="phone_start"
//                           type="number"
//                           required
//                         />
//                       </div>
//                     </div>
//                   </CCol>
//                   <CCol xs="6">
//                     <CFormGroup>
//                       <CLabel
//                         htmlFor="name_start"
//                         style={{ fontWeight: "bold" }}
//                       >
//                         Tên người liên hệ
//                       </CLabel>
//                       <CInput
//                         defaultValue={order ? order.name_user : ""}
//                         name="name_start"
//                         required
//                       />
//                     </CFormGroup>
//                   </CCol>
//                 </CFormGroup>
//                 <CRow>
//                   <CCol xs={"6"}>
//                     <CFormGroup>
//                       <CLabel
//                         htmlFor="address_start"
//                         style={{ fontWeight: "bold" }}
//                       >
//                         Địa chỉ
//                       </CLabel>
//                       <CTextarea
//                         defaultValue={order ? order.address : ""}
//                         name="address_start"
//                         rows="3"
//                         required
//                       />
//                     </CFormGroup>
//                   </CCol>
//                   <CCol xs={"6"}>
//                     {order?.latitude ? (
//                       <CFormGroup>
//                         <CLabel
//                           htmlFor="coordinates_start"
//                           style={{ fontWeight: "bold" }}
//                         >
//                           Toạ độ
//                         </CLabel>
//                         <CInput
//                           defaultValue={
//                             order ? `${order.latitude}, ${order.longitude}` : ""
//                           }
//                           name="coordinates_start"
//                           required
//                         />
//                       </CFormGroup>
//                     ) : (
//                       <CFormGroup>
//                         <CLabel
//                           htmlFor="address_start"
//                           style={{ fontWeight: "bold" }}
//                         >
//                           Chi tiết đơn
//                         </CLabel>
//                         <CTextarea
//                           defaultValue={order?.flash_detail_buy || ""}
//                           name="flash_detail_buy"
//                           rows="3"
//                           required
//                         />
//                       </CFormGroup>
//                     )}
//                   </CCol>
//                 </CRow>
//               </div>
//             </CCardBody>
//           </CCard>

//           {order?.service_deliveries[0] && (
//             <CCard>
//               <CCardHeader>Thông tin điểm giao tới</CCardHeader>
//               <CCardBody style={{ paddingBottom: 0 }}>
//                 <div>
//                   <CFormGroup row className="my-0">
//                     <CCol xs="6">
//                       <div
//                         style={{
//                           display: "flex",
//                           flexDirection: "row",
//                           alignItems: "flex-end",
//                         }}
//                       >
//                         <div style={{ flex: 1 }}>
//                           <CLabel
//                             htmlFor="phone_end"
//                             style={{ fontWeight: "bold" }}
//                           >
//                             SĐT liên hệ
//                           </CLabel>
//                           <CInput
//                             defaultValue={
//                               order
//                                 ? formatPhone(order.service_deliveries[0].phone)
//                                 : ""
//                             }
//                             name="phone_end"
//                             type="number"
//                             required
//                           />
//                         </div>
//                       </div>
//                     </CCol>
//                     <CCol xs="6">
//                       <CFormGroup>
//                         <CLabel
//                           htmlFor="name_end"
//                           style={{ fontWeight: "bold" }}
//                         >
//                           Tên người liên hệ
//                         </CLabel>
//                         <CInput
//                           defaultValue={
//                             order ? order.service_deliveries[0].name : ""
//                           }
//                           name="name_end"
//                           required
//                         />
//                       </CFormGroup>
//                     </CCol>
//                   </CFormGroup>
//                   <CRow>
//                     <CCol xs={"8"}>
//                       <CFormGroup>
//                         <CLabel
//                           htmlFor="address_end"
//                           style={{ fontWeight: "bold" }}
//                         >
//                           Địa chỉ
//                         </CLabel>
//                         <CTextarea
//                           defaultValue={
//                             order ? order.service_deliveries[0].address : ""
//                           }
//                           name="address_end"
//                           rows="3"
//                           required
//                         />
//                       </CFormGroup>
//                     </CCol>
//                     <CCol xs={"4"}>
//                       <CFormGroup>
//                         <CLabel
//                           htmlFor="coordinates_end"
//                           style={{ fontWeight: "bold" }}
//                         >
//                           Toạ độ
//                         </CLabel>
//                         <CInput
//                           defaultValue={
//                             order
//                               ? `${order.service_deliveries[0].latitude}, ${order.service_deliveries[0].longitude}`
//                               : ""
//                           }
//                           name="coordinates_end"
//                           required
//                         />
//                       </CFormGroup>
//                     </CCol>
//                   </CRow>
//                 </div>
//               </CCardBody>
//             </CCard>
//           )}

//           <CFormGroup row>
//             <CCol xs="6">
//               <CLabel htmlFor="note" style={{ fontWeight: "bold" }}>
//                 Ghi chú, dặn dò cho tài xế
//               </CLabel>
//               <CTextarea
//                 defaultValue={order ? order.note : ""}
//                 name="note"
//                 rows="2"
//                 placeholder={"Viết lời ghi chú, dặn dò cho tài xế (nếu có)"}
//               />
//             </CCol>
//             <CCol xs="6">
//               <CLabel htmlFor="note_private" style={{ fontWeight: "bold" }}>
//                 Ghi chú riêng tư
//               </CLabel>
//               <CTextarea
//                 defaultValue={order ? order.note_private : ""}
//                 name="note_private"
//                 rows="2"
//                 placeholder="Ghi chú riêng tư chỉ hiển thị cho văn phòng"
//               />
//             </CCol>
//           </CFormGroup>
//         </CForm>
//       </CModalBody>
//       <CModalFooter style={{ padding: 5 }}>
//         <CButton color="secondary" onClick={handleCloseModal}>
//           Đóng
//         </CButton>
//         <CButton
//           disabled={isLoadingSubmit}
//           color={"success"}
//           form={ID_FORM_ORDER}
//           type={"submit"}
//         >
//           {isLoadingSubmit && (
//             <CSpinner component="span" size="sm" aria-hidden="true" />
//           )}
//           Cập nhật
//         </CButton>
//       </CModalFooter>
//     </div>
//   );
// };

// export default FormOrderDelivery;

import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CLabel,
  CModalBody,
  CModalFooter,
  CRow,
  CSpinner,
  CTextarea,
} from "@coreui/react";
import { ID_FORM_ORDER } from "../RenderModalFormOrder";
import axios from "axios";
import { apis } from "src/constants";
import refreshToken from "src/token/refreshToken";
import {
  formatPhone,
  formatPhoneForSave,
  maxLengthCheck,
} from "src/functions/baseFunction";
import Select from "react-select";
import { getOptionListAdmin } from "src/functions/getData";
import { useSelector } from "react-redux";

const FormOrderDelivery = ({ data, handleCloseModal }) => {
  const listAdminRedux = useSelector((state) => state.listAdmin.data);

  const [validated, setValidated] = useState(false);
  const [order, setOrder] = useState(null);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const optionsAdmin = getOptionListAdmin(listAdminRedux);

  useEffect(() => {
    if (data.admin_id) {
      setSelectedAdmins(JSON.parse(data.admin_id));
    }

    setOrder(data);
  }, []);

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingSubmit(true);
      await updateInfo(event);
      setLoadingSubmit(false);
    }
    setValidated(true);
  };

  const updateInfo = async (event) => {
    const {
      phone_start,
      name_start,
      address_start,
      coordinates_start,

      phone_end,
      name_end,
      address_end,
      coordinates_end,

      delivery_charge,
      note,
      note_private,
    } = event.target.elements;

    const body = {
      name_start: name_start.value,
      phone_start: formatPhoneForSave(phone_start.value),
      address_start: address_start.value,
      coordinates_start: coordinates_start.value,
      name_end: name_end.value,
      phone_end: phone_end.value,
      address_end: address_end.value,
      coordinates_end: coordinates_end.value,
      delivery_charge: delivery_charge.value
        ? parseFloat(delivery_charge.value) * 1000
        : 0,
      note: note.value,
      note_private: note_private.value,
      admin_id: JSON.stringify(selectedAdmins),
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.put(
        apis.apiOrder + `admin/update-info/delivery/${order.id}`,
        body,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      handleCloseModal();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await updateInfo(event);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  return (
    <div>
      <CModalBody>
        <CForm
          onSubmit={handleSubmitForm}
          id={ID_FORM_ORDER}
          noValidate
          wasValidated={validated}
          autoComplete="off"
        >
          <CFormGroup row>
            <CCol md="9">
              <CLabel htmlFor="delivery_charge" style={{ fontWeight: "bold" }}>
                Nhân viên trực
              </CLabel>
              <Select
                isMulti
                placeholder="Chọn nhân viên trực"
                options={optionsAdmin}
                value={optionsAdmin.filter((obj) =>
                  selectedAdmins.includes(obj.value)
                )}
                onChange={(e) =>
                  setSelectedAdmins(
                    Array.isArray(e) ? e.map((x) => x.value) : []
                  )
                }
                // isDisabled={type === TYPE_EDIT ? true : false}
              />
            </CCol>
            <CCol xs="3">
              <CFormGroup>
                <CLabel
                  htmlFor="delivery_charge"
                  style={{ fontWeight: "bold" }}
                >
                  Phí giao hàng
                </CLabel>
                <CFormGroup row>
                  <CCol>
                    <CInputGroup>
                      <CInput
                        defaultValue={
                          order ? parseInt(order.delivery_charge) / 1000 : ""
                        }
                        name="delivery_charge"
                        type="number"
                        maxLength="4"
                        onInput={maxLengthCheck}
                        onWheel={(e) => e.target.blur()}
                      />
                      <CInputGroupAppend>
                        <CInputGroupText>.000</CInputGroupText>
                      </CInputGroupAppend>
                    </CInputGroup>
                  </CCol>
                </CFormGroup>
              </CFormGroup>
            </CCol>
          </CFormGroup>
          <CCard>
            <CCardHeader>Thông tin điểm lấy hàng</CCardHeader>
            <CCardBody style={{ paddingBottom: 0 }}>
              <div>
                <CFormGroup row className="my-0">
                  <CCol xs="6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <CLabel
                          htmlFor="phone_start"
                          style={{ fontWeight: "bold" }}
                        >
                          SĐT liên hệ
                        </CLabel>
                        <CInput
                          defaultValue={order ? formatPhone(order.phone) : ""}
                          name="phone_start"
                          type="number"
                          required
                        />
                      </div>
                    </div>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel
                        htmlFor="name_start"
                        style={{ fontWeight: "bold" }}
                      >
                        Tên người liên hệ
                      </CLabel>
                      <CInput
                        defaultValue={order ? order.name_user : ""}
                        name="name_start"
                        required
                      />
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CRow>
                  <CCol xs={"8"}>
                    <CFormGroup>
                      <CLabel
                        htmlFor="address_start"
                        style={{ fontWeight: "bold" }}
                      >
                        Địa chỉ
                      </CLabel>
                      <CTextarea
                        defaultValue={order ? order.address : ""}
                        name="address_start"
                        rows="3"
                        required
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol xs={"4"}>
                    <CFormGroup>
                      <CLabel
                        htmlFor="coordinates_start"
                        style={{ fontWeight: "bold" }}
                      >
                        Toạ độ
                      </CLabel>
                      <CInput
                        defaultValue={
                          order ? `${order.latitude}, ${order.longitude}` : ""
                        }
                        name="coordinates_start"
                        required
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
              </div>
            </CCardBody>
          </CCard>

          <CCard>
            <CCardHeader>Thông tin điểm giao tới</CCardHeader>
            <CCardBody style={{ paddingBottom: 0 }}>
              <div>
                <CFormGroup row className="my-0">
                  <CCol xs="6">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                      }}
                    >
                      <div style={{ flex: 1 }}>
                        <CLabel
                          htmlFor="phone_end"
                          style={{ fontWeight: "bold" }}
                        >
                          SĐT liên hệ
                        </CLabel>
                        <CInput
                          defaultValue={
                            order
                              ? formatPhone(order.service_deliveries[0].phone)
                              : ""
                          }
                          name="phone_end"
                          type="number"
                          required
                        />
                      </div>
                    </div>
                  </CCol>
                  <CCol xs="6">
                    <CFormGroup>
                      <CLabel htmlFor="name_end" style={{ fontWeight: "bold" }}>
                        Tên người liên hệ
                      </CLabel>
                      <CInput
                        defaultValue={
                          order ? order.service_deliveries[0].name : ""
                        }
                        name="name_end"
                        required
                      />
                    </CFormGroup>
                  </CCol>
                </CFormGroup>
                <CRow>
                  <CCol xs={"8"}>
                    <CFormGroup>
                      <CLabel
                        htmlFor="address_end"
                        style={{ fontWeight: "bold" }}
                      >
                        Địa chỉ
                      </CLabel>
                      <CTextarea
                        defaultValue={
                          order ? order.service_deliveries[0].address : ""
                        }
                        name="address_end"
                        rows="3"
                        required
                      />
                    </CFormGroup>
                  </CCol>
                  <CCol xs={"4"}>
                    <CFormGroup>
                      <CLabel
                        htmlFor="coordinates_end"
                        style={{ fontWeight: "bold" }}
                      >
                        Toạ độ
                      </CLabel>
                      <CInput
                        defaultValue={
                          order
                            ? `${order.service_deliveries[0].latitude}, ${order.service_deliveries[0].longitude}`
                            : ""
                        }
                        name="coordinates_end"
                        required
                      />
                    </CFormGroup>
                  </CCol>
                </CRow>
              </div>
            </CCardBody>
          </CCard>

          <CFormGroup row>
            <CCol xs="6">
              <CLabel htmlFor="note" style={{ fontWeight: "bold" }}>
                Ghi chú, dặn dò cho tài xế
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note : ""}
                name="note"
                rows="2"
                placeholder={"Viết lời ghi chú, dặn dò cho tài xế (nếu có)"}
              />
            </CCol>
            <CCol xs="6">
              <CLabel htmlFor="note_private" style={{ fontWeight: "bold" }}>
                Ghi chú riêng tư
              </CLabel>
              <CTextarea
                defaultValue={order ? order.note_private : ""}
                name="note_private"
                rows="2"
                placeholder="Ghi chú riêng tư chỉ hiển thị cho văn phòng"
              />
            </CCol>
          </CFormGroup>
        </CForm>
      </CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={handleCloseModal}>
          Đóng
        </CButton>
        <CButton
          disabled={isLoadingSubmit}
          color={"success"}
          form={ID_FORM_ORDER}
          type={"submit"}
        >
          {isLoadingSubmit && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Cập nhật
        </CButton>
      </CModalFooter>
    </div>
  );
};

export default FormOrderDelivery;
