const initialState = {
  data: [],
};


const newOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_NEW_ORDER": {
      return {
        data: action.payload,
      };
    }

    case "EDIT_ITEM_NEW_ORDER": {
      const dataState = state.data;
      const payload = action.payload;

      return { data: dataState.map((el) =>
        el.id === parseInt(payload.order_id) ? { ...el, ...payload.model } : el
      ) };
    }

    case "PUSH_BEFORE_NEW_ORDER": {

      const data = state.data;
      const payload = action.payload;

      const indexItem = data.findIndex((i) => i.id === payload.id);

      if (indexItem !== -1) {
        return { data };
      }

      return {
        data: [payload, ...data],
      };

    }

    case "REMOVE_ITEM_NEW_ORDER": {
      return {
        data: state.data.filter((item) => item.id !== action.payload),
      };
    }

    default:
      return state;
  }
};

export default newOrderReducer;
