const initialState = {
  visible: false,
};

const modalMapDeliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SHOW_MODAL_MAP_DELIVERY": {
      return {
        visible: true,
      };
    }

    case "CLOSE_MODAL_MAP_DELIVERY": {
      return {
        visible: false,
      };
    }

    default:
      return state;
  }
};

export default modalMapDeliveryReducer;
