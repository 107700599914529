import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModalBody,
  CModalFooter,
  CSpinner,
} from "@coreui/react";
import { ID_FORM_ORDER } from "../RenderModalFormOrder";
import axios from "axios";
import { apis } from "src/constants";
import refreshToken from "src/token/refreshToken";
import { formatPhone, formatPhoneForSave } from "src/functions/baseFunction";
import { getOptionListAdmin } from "src/functions/getData";
import { useSelector } from "react-redux";
import Select from "react-select";

const FormOrderOther = ({ data, handleCloseModal }) => {
  const listAdminRedux = useSelector((state) => state.listAdmin.data);

  const [validated, setValidated] = useState(false);
  const [order, setOrder] = useState(null);
  const [isLoadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedAdmins, setSelectedAdmins] = useState([]);
  const optionsAdmin = getOptionListAdmin(listAdminRedux);

  useEffect(() => {
    if (data.admin_id) {
      setSelectedAdmins(JSON.parse(data.admin_id));
    }

    setOrder(data);
  }, []);

  const handleSubmitForm = async (event) => {
    event.preventDefault();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setLoadingSubmit(true);
      await updateInfo(event);
      setLoadingSubmit(false);
    }
    setValidated(true);
  };

  const updateInfo = async (event) => {
    const { phone, name_user } = event.target.elements;

    const body = {
      name_user: name_user.value,
      phone: formatPhoneForSave(phone.value),
      admin_id: JSON.stringify(selectedAdmins),
    };

    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      await axios.put(
        apis.apiOrder + `admin/update-info/other/${order.id}`,
        body,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      handleCloseModal();
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await updateInfo(event);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  return (
    <div>
      <CModalBody>
        <CForm
          onSubmit={handleSubmitForm}
          id={ID_FORM_ORDER}
          noValidate
          wasValidated={validated}
          autoComplete="off"
        >
          <CFormGroup row>
            <CCol md="9">
              <CLabel htmlFor="delivery_charge" style={{ fontWeight: "bold" }}>
                Nhân viên trực
              </CLabel>
              <Select
                isMulti
                placeholder="Chọn nhân viên trực"
                options={optionsAdmin}
                value={optionsAdmin.filter((obj) =>
                  selectedAdmins.includes(obj.value)
                )}
                onChange={(e) =>
                  setSelectedAdmins(
                    Array.isArray(e) ? e.map((x) => x.value) : []
                  )
                }
                // isDisabled={type === TYPE_EDIT ? true : false}
              />
            </CCol>
          </CFormGroup>

          <CCard>
            <CCardHeader>Thông tin liên hệ khách</CCardHeader>
            <CCardBody style={{ paddingBottom: 0 }}>
              <CFormGroup row>
                <CCol xs="8">
                  <CFormGroup>
                    <CLabel htmlFor="name_user" style={{ fontWeight: "bold" }}>
                      Tên liên hệ
                    </CLabel>
                    <CInput
                      defaultValue={order ? order.name_user : ""}
                      name="name_user"
                      required
                    />
                  </CFormGroup>
                </CCol>
                <CCol xs="4">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                    }}
                  >
                    <div style={{ flex: 1 }}>
                      <CLabel htmlFor="phone" style={{ fontWeight: "bold" }}>
                        SĐT liên hệ
                      </CLabel>
                      <CInput
                        defaultValue={order ? formatPhone(order.phone) : ""}
                        name="phone"
                        type="number"
                        required
                      />
                    </div>
                  </div>
                </CCol>
              </CFormGroup>
            </CCardBody>
          </CCard>
        </CForm>
      </CModalBody>
      <CModalFooter style={{ padding: 5 }}>
        <CButton color="secondary" onClick={handleCloseModal}>
          Đóng
        </CButton>
        <CButton
          disabled={isLoadingSubmit}
          color={"success"}
          form={ID_FORM_ORDER}
          type={"submit"}
        >
          {isLoadingSubmit && (
            <CSpinner component="span" size="sm" aria-hidden="true" />
          )}
          Cập nhật
        </CButton>
      </CModalFooter>
    </div>
  );
};

export default FormOrderOther;
