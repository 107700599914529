const getOptionListAdmin = (listAdmin) => {
  return listAdmin.map((item) => {
    return { value: item.id, label: item.name };
  });
};

const getOptionListRestaurantCategory = (list) => {
  return list.map((item) => {
    return { value: item.id, label: item.name };
  });
};

const getUniqueOrderId = () => {
  const current = new Date();
  return `${current.getFullYear().toString().substring(2, 4)}${
    current.getMonth() + 1
  }${current.getDate()}${current.getHours()}${current.getMinutes()}${current.getSeconds()}${current.getMilliseconds()}`;
};


export { getOptionListAdmin, getOptionListRestaurantCategory, getUniqueOrderId };
