const initialState = {
  data: [],
};

const pageRouteReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PAGE_ROUTE": {
      const dataState = state.data;
      const payload = action.payload;

      const indexChange = dataState.findIndex(
        (item) => item.route === payload.route
      );

      if (indexChange < 0) {
        return {
          data: [...dataState, payload],
        };
      }

      return { data: dataState.map((el) =>
        el.route === payload.route ? { ...el, ...payload } : el
      )};
    }

    default:
      return state;
  }
};

export default pageRouteReducer;
