import React, { useEffect, useState } from "react";

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CInputGroupText,
  CInvalidFeedback,
  CSelect,
  CSwitch,
  CTextarea,
} from "@coreui/react";
import axios from "axios";
import { apis } from "src/constants";
import { formatPrice } from "src/functions/baseFunction";
import refreshToken from "src/token/refreshToken";
import RenderModal from "src/views/utils/RenderModal";
import { useDispatch, useSelector } from "react-redux";
import { closeModalSurchargeService } from "src/redux/actions/global/modal/modalSurchargeService";
import { inputTypePrice } from "src/functions/typeInput";

const RenderModalSurchargeService = () => {
  const dispatch = useDispatch();

  const userRedux = useSelector((state) => state.user.data);
  const { visible } = useSelector((state) => state.modalSurchargeService);

  const [surchargeServices, setSurchargeServices] = useState([]);

  const handleCloseModal = () => {
    dispatch(closeModalSurchargeService());
  };

  useEffect(() => {
    async function fetchData() {
      await fetchSurchargeServices();
    }
    fetchData();
  }, [visible]);

  const fetchSurchargeServices = async () => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.get(apis.apiSurchargeService + "admin/all", {
        headers: {
          verify: userLocal.accessToken,
        },
      });

      const data = response.data;

      setSurchargeServices(data);
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await fetchSurchargeServices();
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  const changeStatusSurchargeService = async (id, model) => {
    const userLocal = JSON.parse(localStorage.getItem("@loginData"));
    try {
      const response = await axios.put(
        apis.apiSurchargeService + `admin/update/${id}/${userRedux.id}`,
        model,
        {
          headers: {
            verify: userLocal.accessToken,
          },
        }
      );

      const modelUpdated = response.data;

      const newSurchargeServices = surchargeServices.map((el) =>
        el.id === modelUpdated.id ? { ...el, ...modelUpdated } : el
      );

      setSurchargeServices(newSurchargeServices);
    } catch (error) {
      if (error.response?.status === 401) {
        await refreshToken(userLocal);
        await changeStatusSurchargeService(id, model);
      } else if (error.response?.status === 400) {
        alert(error.response.data);
      } else {
        alert(error.response.data);
      }
    }
  };

  const OneSurchargeService = ({ surcharge }) => {
    const { id, name, content, value, is_active } = surcharge;

    const [stateContent, setStateContent] = useState("DEFAULT");
    const [stateValue, setStateValue] = useState(value ? value : "");

    useEffect(() => {
      async function fetchData() {
        if (content && content.includes("mưa")) {
          setStateContent("1");
        }else {
          setStateContent("DEFAULT");
        }
      }
      fetchData();
    }, []);

    return (
      <CCard>
        <CCardHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {name}
            <CSwitch
              className={"mx-1"}
              shape={"pill"}
              color={"primary"}
              labelOn={"\u2713"}
              labelOff={"\u2715"}
              checked={is_active}
              onChange={() => {
                const model = {
                  content: stateContent,
                  value: stateValue,
                  is_active: !is_active,
                };
                changeStatusSurchargeService(id, model);
              }}
            />
          </div>
        </CCardHeader>
        <CCardBody style={{ paddingBottom: 0 }}>
          <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            <CFormGroup style={{ display: "flex", flex: 1 }}>
              <CSelect
                custom
                value={stateContent}
                onChange={(event) => setStateContent(event.target.value)}
                disabled={is_active}
              >
                <option value="DEFAULT">-- Chọn lý do --</option>
                <option value="1">Trời mưa</option>
              </CSelect>
              {/* <CInput
                // rows="2"
                placeholder={"Nội dung phụ thu"}
                value={stateContent}
                onChange={(e) => setStateContent(e.target.value)}
                disabled={is_active}
              /> */}
              <CInvalidFeedback>Chưa nhập nội dung phụ thu</CInvalidFeedback>
            </CFormGroup>
            <CFormGroup style={{ width: 200, marginLeft: 15 }}>
              <CInputGroup>
                <CInput
                  name="value"
                  placeholder="Số tiền phụ thu"
                  value={formatPrice(stateValue)}
                  onChange={(e) => {
                    const value = inputTypePrice(e.target.value);
                    setStateValue(value);
                  }}
                  disabled={is_active}
                  onWheel={(e) => e.target.blur()}
                />
                <CInputGroupAppend style={{ maxHeight: 35 }}>
                  <CInputGroupText>VNĐ</CInputGroupText>
                </CInputGroupAppend>
              </CInputGroup>
              <CInvalidFeedback>Chưa nhập số tiền phụ thu</CInvalidFeedback>
            </CFormGroup>
          </div>
        </CCardBody>
      </CCard>
    );
  };

  return (
    <RenderModal
      widthModal={700}
      title={"Phụ thu"}
      body={
        <CCol xs="12" sm="12">
          {surchargeServices.map((surcharge) => (
            <OneSurchargeService key={surcharge.id} surcharge={surcharge} />
          ))}
        </CCol>
      }
      color="success"
      modalVisible={visible}
      // titleAccept={"Lưu"}
      // colorAccept="success"
      onClose={handleCloseModal}
      onClickCancel={handleCloseModal}
      onClickAccept={() => {}}
      submit
    />
  );
};

export default RenderModalSurchargeService;
