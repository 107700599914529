const initialState = {
  url: null,
  indexCurrent: 0,
  isOpen: false,
};

const imageViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "OPEN_IMAGE_VIEWER": {
      const { url, indexCurrent } = action.payload;
      return {
        url,
        indexCurrent: indexCurrent ? indexCurrent : 0,
        isOpen: true,
      };
    }

    case "CLOSE_IMAGE_VIEWER": {
      return initialState;
    }

    default:
      return state;
  }
};

export default imageViewerReducer;
