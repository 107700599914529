import { bounceInRight, bounceOutRight, rubberBand, tada } from "react-animations";

const typeBounceInRight = {
  val: bounceInRight,
  key: "bounceInRight",
};

const typeBounceOutRight = {
  val: bounceOutRight,
  key: "bounceOutRight",
};

const typeTada = {
  val: tada,
  key: "tada",
};

const typeRubberBand = {
  val: rubberBand,
  key: "rubberBand",
};

const exportedObject = {
  typeBounceInRight,
  typeBounceOutRight,
  typeRubberBand,
  typeTada,
};

export default exportedObject;
