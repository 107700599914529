export const updateNewOrder = order => ({
  type: 'UPDATE_NEW_ORDER',
  payload: order,
});

export const editItemNewOrder = order => ({
  type: 'EDIT_ITEM_NEW_ORDER',
  payload: order,
});


export const pushBeforeNewOrder = order => ({
  type: 'PUSH_BEFORE_NEW_ORDER',
  payload: order,
});

export const removeItemNewOrder = order_id => ({
  type: 'REMOVE_ITEM_NEW_ORDER',
  payload: order_id,
});
