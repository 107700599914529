import lazyWithRetry from './lazyWithRetry';
import routes from './routes';

const RechargeHistory = lazyWithRetry(() => import('./views/delivery/childrents/RechargeHistory'));

const routes_master = [
  ...routes,
  { path: '/delivery/manager/recharge-history/:delivery_id', exact: true, name: 'Lịch sử nạp ví', component: RechargeHistory },
]

export default routes_master;
